import {
    Box,
    Drawer,
    Typography
} from '@mui/material';
import React from 'react';
import { headerHeight, rightSidebarWidth } from './constants';

export function Feed() {
      /* Right Sidebar */
      return <Drawer
        variant="permanent"
        anchor="right"
        sx={{
          flexShrink: 0,
          display: { xs: 'none', sm: 'none', lg : 'block' },
          width: { xs: 0, sm: rightSidebarWidth },
          [`& .MuiDrawer-paper`]: {
            width: { xs: 0, sm: rightSidebarWidth },
            bgcolor: 'rgba(0, 0, 0, 0.3)',
            boxSizing: 'border-box',
            mt: headerHeight,
            display: { xs: 'none', sm: 'block' },
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="body1" color="white">Right Sidebar Content</Typography>
        </Box>
      </Drawer>
}
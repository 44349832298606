import { FormBox } from 'components/Box/Box';
import { Drawer, styled } from '@mui/material';
import { AppMainTitleBar } from '../TitleBar/Titlebar';
import './drawer.scss';
import 'styles/app-console.scss';

class AppStateHolder {
  static appState = {
    setSideDrawerDisplayed: message => {},
  };
}
export const FullWidthSideDrawer = styled(SideDrawer)({
  '& .MuiDrawer-paperAnchorRight': {
    maxWidth: '100%',
    width : "100vw",
    zIndex: 99999,
    overflowY: 'visible',
    height: '100vh',
  },
});

export function SideDrawer(props) {
  const { headerTitle, onClose, rightIcons, buttonSectionId, ...others } = props;
  const appState = AppStateHolder.appState;
  function handleClose(e) {
    if (onClose && typeof onClose === 'function') {
      onClose(e);
    } else {
      appState.setSideDrawerDisplayed(false);
    }
  }
  return (
    <Drawer className='sideDrawer' onClose={handleClose} {...others}>
      <AppMainTitleBar
        fixed
        onClose={handleClose}
        rightIcons={rightIcons}
        title={props.headerTitle ? props.headerTitle.toUpperCase() : ''}
        buttonSectionId={buttonSectionId}></AppMainTitleBar>
      {props.children}
    </Drawer>
  );
}

export function HeaderLessSideDrawer(props) {
  const { onClose, rightIcons, ...others } = props;

  function handleClose(e) {
    if (onClose && typeof onClose === 'function') {
      onClose(e);
    }
  }
  return (
    <Drawer onClose={handleClose} {...others}>
      {props.children}
    </Drawer>
  );
}

export function SideDrawerDatacontainer(props) {
  const { headerTitle, onClose, rightIcons, ...others } = props;

  return (
    <>
      <SideDrawer headerTitle={headerTitle} onClose={onClose} rightIcons={rightIcons} {...others}>
        <SideDrawerInternalDataContainer>{props.children}</SideDrawerInternalDataContainer>
      </SideDrawer>
    </>
  );
}

export function SideDrawerInternalDataContainer(props) {
  return (
    <>
      <FormBox sx={{ paddingLeft: '15px', paddingRight: '15px', paddingTop: '10px' }}>{props.children}</FormBox>
    </>
  );
}

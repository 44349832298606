import { Box, Drawer, IconButton, Typography } from '@mui/material';
import StringUtil from 'utils/StringUtil';
import NavService from 'service/NavService';
import PanelTypeEnum from 'common/enums/PanelTypeEnum';
import useAppState from 'hooks/useAppState';
import { bottomBarHeight, headerHeight, sidebarWidthCollapsed, sidebarWidthExpanded } from './constants';
import { useEffect, useState } from 'react';

export function Nav() {
  const { appNavDisplayed, currentPanel, setAppMenuDisplayed, appMenuDisplayed, setApplication, application } = useAppState();
  const [leftSidebarItems, setLeftSidebarItems] = useState([]);

  useEffect(() => {
    NavService.getNavItems(PanelTypeEnum.DEFAULT).then(items => {
      const filteredSidebarItems = items.filter(i => currentPanel?.authorities?.filter(a => StringUtil.equalsIgnoreCase(a.permission, i.permission)).length > 0);
      setLeftSidebarItems(filteredSidebarItems);
    });
  }, [currentPanel]);

  function handleAppSelection(item) {
    setApplication(item);

    if (item.name === application?.name) {
      setAppMenuDisplayed(!appMenuDisplayed);
    } else {
      setAppMenuDisplayed(true);
    }
  }

  {
    /* Left Sidebar */
  }
  return (
    <>
      <Drawer
        variant='permanent'
        sx={{
          display: { xs: 'none', sm: 'block' },
          width: appNavDisplayed ? sidebarWidthExpanded : sidebarWidthCollapsed,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: appNavDisplayed ? sidebarWidthExpanded : sidebarWidthCollapsed,
            boxSizing: 'border-box',
            bgcolor: '#f3f2f1',
            mt: headerHeight,
            pt: 2,
          },
        }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {leftSidebarItems.map(item => (
            <Box key={item.name} sx={{ display: 'flex', alignItems: 'center', p: 1 }} onClick={() => handleAppSelection(item)}>
              <IconButton color='inherit'>{item.icon}</IconButton>
              {appNavDisplayed && (
                <Typography variant='body2' sx={{ ml: 1 }}>
                  {item.name}
                </Typography>
              )}
            </Box>
          ))}
        </Box>
      </Drawer>

      {/* Bottom Bar on Mobile */}
      <Box
        sx={{
          display: { xs: 'flex', sm: 'none' },
          position: 'fixed',
          bottom: 0,
          width: '100%',
          height: bottomBarHeight,
          bgcolor: '#FFF',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          {leftSidebarItems.map(item => {
            return (
              <Box key={item.name} sx={{ display: 'flex', alignItems: 'center', p: 1 }} onClick={() => handleAppSelection(item)}>
                <IconButton color='inherit'>{item.icon}</IconButton>
                {appNavDisplayed && (
                  <Typography variant='body2' sx={{ ml: 1 }}>
                    {item.name}
                  </Typography>
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
}

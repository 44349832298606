import { useState } from "react"
import { useTranslation } from "react-i18next"
import Search from "components/object-list/Search/Search"
import ObjectList from "../../../components/object-list/ObjectList"
import { HorizontalTab, UiTabContext, UiTabPanel } from "components/Tabs"
import { AccountReview } from "./AccountReview"
import { SideDrawer } from "components/Drawer/Drawer"
import PermissionBoundary from "components/security/PermissionBoundary"
import { PageableDataManagerProvider } from "contexts/datamanager/PageableDataManagerContext"
import { AddButton, UploadButton } from "components/Buttons/ActionButtons"

const ACCOUNT_CREATE="/account/create"
const ACCOUNT_PAGE="/cloud/account/page"
const ACCOUNT_REVIEW="/cloud/account/review?ac="
const ACCOUNT_UPLOAD="/cloud/account/import"

export function AccountListingPage() {
    const {t} = useTranslation();
    const [keywords, setKeywords] = useState(null);
    
    const tabs = [
        <HorizontalTab label="All" value="all" key="all"/>
        , <HorizontalTab label="Pending Authorization" value="open" key="open"/>
        , <HorizontalTab label="Pending Activation" value="authorized" key="authorized"/>
    ]
    return (
        <PermissionBoundary permissions={['AdministerSystem']}>
            <div className="applist applistshow">
                <div className="applistheader">
                    <AddButton url={ACCOUNT_CREATE} />
                    <UploadButton url={ACCOUNT_UPLOAD} />
                    <Search title={t("account-administration")} setKeywords={setKeywords} />
                </div>
                <div className="applistframe">
                    <UiTabContext tabs={tabs} defaultTab={"all"}>
                        <UiTabPanel value="all" >
                            <AllAccountList data={{legalName : keywords}}/>
                        </UiTabPanel>
                        <UiTabPanel value="open" >
                            <PendingAuthorizationAccountList data={{legalName : keywords}}/>
                        </UiTabPanel>
                        <UiTabPanel value="authorized" >
                            <PendingActivationAccountList data={{legalName : keywords}}/>
                        </UiTabPanel>
                    </UiTabContext>

                </div>
            </div>
        </PermissionBoundary>
    )
}

function AllAccountList({data}) {
    return <AccountList data={{...data}} />
}

function PendingAuthorizationAccountList({data}) {
    return <AccountList data={{...data, "state" : "OPEN"}} />
}

function PendingActivationAccountList({data}) {
    return <AccountList data={{...data, "state" : "AUTHORIZED"}} />
}


function AccountList({data}) {
    const [showDetails, setShowDetails] = useState(false);
    const [systemCode, setSystemCode] = useState();
    const {t} = useTranslation();
    function handleShowDetails(systemCode) {
        setSystemCode(systemCode);
        setShowDetails(true);
    }

    return (<>
        {showDetails && <SideDrawer anchor="right" headerTitle={t("account")} open={showDetails} onClose={() => setShowDetails(false)}>
            {systemCode && <AccountReview systemCode={systemCode} />}
        </SideDrawer>
        }
        <PageableDataManagerProvider url={ACCOUNT_PAGE} >
            <ObjectList 
                data={data}
                initials={{text : 'name', id : 'systemCode'}} 
                onRowClick={(item) => handleShowDetails(item.systemCode)}            
                headCells={[
                    {    
                        id: 'virtualId',
                        numeric: false,
                        label: 'Handle',
                        nuggetInfo : {
                            order : 2,
                            type : 'subtitle'
                        },
                        formatter : (item) => '@' + item.virtualId
                    },
                    {    
                        id: 'name',
                        numeric: false,
                        label: 'Name',
                        nuggetInfo : {
                            order : 1,
                            type : 'title'
                        }
                    },
                    {    
                        id: 'legalName',
                        numeric: false,
                        label: 'Legal Name',
                        nuggetInfo : {
                            order : 3,
                            type : 'subtitle'
                        }
                    },
                    {    
                        id: 'state',
                        numeric: false,
                        label: 'Status',
                        nuggetInfo : {
                            order : 4,
                            type : 'subtitle'
                        }
                    }
                    ]} 
            />
        </PageableDataManagerProvider>
    </> 
    )

}
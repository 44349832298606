import React, { useState } from 'react';
import './search.scss';

/**
 * A search component.
 */
function Search(props) {
    const [searchDisplayed, setSearchDisplayed] = useState(false);

    /**
     * Toggles search.
     */
    function toggleSearch() {
        setSearchDisplayed(!searchDisplayed);

        if (searchDisplayed) {
            props.setKeywords(null);
        }
    }

    /**
     * Handles search.
     */
    function handleSearch(event) {
        let keywords = event.target.value;
        props.setKeywords(keywords);
    }

    return (
        <React.Fragment>
            {
                searchDisplayed
                    ? <div className="applisttitle">
                        <input type="text" autoFocus="autoFocus " placeholder="Search" className="search" onChange={handleSearch}></input>
                    </div>
                    : <div className="applisttitle" onClick={toggleSearch}>{props.title}</div>

            }
            <div className="applistfiltersclick applistfiltershide" onClick={toggleSearch}>
                {
                    searchDisplayed
                        ? <i className="fas fa-times">&nbsp;</i>
                        : <i className="fas fa-search">&nbsp;</i>
                }
            </div>
        </React.Fragment>
    )
}

export default Search;
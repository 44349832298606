
import styleVariables from "styles/appvariables.scss";


export class ScreenUtil {

    static getScreenType(element) {
        const largeScreenCutoff = styleVariables.largeBreakpoint.replace("px", "");
        const tabletScreenCutoff = styleVariables.tabletBreakpoint.replace("px", "");
        const laptopScreenCutoff = styleVariables.laptopBreakpoint.replace("px", "");

        const currentScreenSize = element ? element.offsetWidth : window.innerWidth;
        if (currentScreenSize < tabletScreenCutoff) {
            return ScreenTypeEnum.MOBILE
        }

        if (currentScreenSize >= tabletScreenCutoff && currentScreenSize < laptopScreenCutoff) {
            return ScreenTypeEnum.TABLET;
        }

        if (currentScreenSize >= laptopScreenCutoff && currentScreenSize < largeScreenCutoff) {
            return ScreenTypeEnum.LAPTOP;
        }

        if (currentScreenSize >= largeScreenCutoff) {
            return ScreenTypeEnum.DESKTOP;
        }
    }

    static isMobile(element) {
        return ScreenUtil.getScreenType(element) === ScreenTypeEnum.MOBILE;
    }

    static isTablet(element) {
        return ScreenUtil.getScreenType(element) === ScreenTypeEnum.TABLET;
    }

    static isLaptop(element) {
        return ScreenUtil.getScreenType(element) === ScreenTypeEnum.LAPTOP;
    }

    static isDesktop(element) {
        return ScreenUtil.getScreenType(element) === ScreenTypeEnum.DESKTOP;
    }

    static getListNuggetMaxWidthPc() {
        if (!ScreenUtil.isMobile()) {
            return "50%";
        } else {
            return "100%";
        }
    }
}

export class ScreenTypeEnum {
    static DESKTOP = "Desktop";
    static LAPTOP = "Laptop";
    static MOBILE = "Mobile";
    static TABLET = "Tablet";
}


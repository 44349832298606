import axios from 'axios';
import 'fake-db';
import Mock, { axiosInstanceForMocking } from 'fake-db/mock';
import useAppErrors from 'hooks/useAppErrors';
import { createContext, useState } from 'react';
import UrlUtil from 'utils/UrlUtils';
import { getTokenFromCookie } from '../utils/utils';
import { SuccessSnackbar } from './ErrorSuccessContext';

const HttpContext = createContext({
  get: async () => {},
  post: () => {},
  postMultipart: async () => {},
});

export function HttpContextProvider({ children }) {
  const { setAppErrors } = useAppErrors();
  const [showSuccess, setShowSuccess] = useState(false);
  const [getMockedPaths] = useState(Mock.handlers.get.map(a => a.url));
  const [postMockedPaths] = useState(Mock.handlers.post.map(a => a.url));

  const get = async (url, data, hideErrors) => {
    const resolvedUrl = UrlUtil.getPanelUrl(url);
    const token = getTokenFromCookie();
    const headers = {};
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const mocked = getMockedPaths.includes(url);
    return mocked
      ? axiosInstanceForMocking
          .get(url, {
            headers: { ...headers },
            params: data, // Query parameters
            withCredentials: true,
          })
          .then(response => (response.data ? response.data : {}))
      : axios
          .get(resolvedUrl, {
            headers: { ...headers },
            params: data, // Query parameters
            withCredentials: true,
          })
          .then(response => (response.data ? response.data : {}))
          .catch(function (e) {
            if (!hideErrors) {
              if (e.status === 403) {
                setAppErrors([{ message: 'You do not have sufficient permission to perform this action.' }]);
              } else {
                setAppErrors([{ message: 'An unexpected error has been encountered. Please try after sometime.' }]);
              }
            }
          });
  };

  const post = async (url, data, onSuccess) => {
    const resolvedUrl = UrlUtil.getPanelUrl(url);
    setShowSuccess(false);
    const token = getTokenFromCookie();
    const headers = {};
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const mocked = postMockedPaths.includes(url);
    const response = mocked
      ? await axiosInstanceForMocking.post(url, data, {
          headers: { ...headers },
          withCredentials: true,
        })
      : await axios
          .post(resolvedUrl, data, {
            headers: { ...headers },
            withCredentials: true,
          })
          .catch(function (e) {
            if (e.status === 403) {
              setAppErrors([{ message: 'You do not have sufficient permission to perform this action.' }]);
            } else {
              setAppErrors([{ message: 'An unexpected error has been encountered. Please try after sometime.' }]);
            }
          });
    if (response && response.data && response.data.errors && response.data.errors.length > 0) {
      setAppErrors(response.data.errors);
    } else {
      setShowSuccess(true);
    }
    return response ? response : { data: { errors: 'An unexpected error has been encountered. Please try after sometime.' } };
  };

  const postMultipart = async (url, data, files, onSuccess) => {
    const resolvedUrl = UrlUtil.getPanelUrl(url);
    setShowSuccess(false);
    const token = getTokenFromCookie();
    const headers = {};
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    var buffer = new FormData();
    Object.keys(data).forEach(key => buffer.append(key, data[key]));
    files.forEach((file, i) => buffer.append('files', file));

    const response = await axios
      .post(resolvedUrl, buffer, {
        headers: { ...headers },
        withCredentials: true,
        contentType: false, // Required to submit multipart requests.
        processData: false, // Required to avoid form data processing.
      })
      .catch(function () {
        setAppErrors([{ message: 'An unexpected error has been encountered. Please try after sometime.' }]);
      });

    if (response) {
      if (response && response.errors && response.errors.length > 0) {
        setAppErrors(response.errors);
      } else {
        setShowSuccess(true);
        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess(response);
        }
      }
    }
  };

  return (
    <HttpContext.Provider value={{ get, post, postMultipart }}>
      {showSuccess && <SuccessSnackbar />}
      {children}
    </HttpContext.Provider>
  );
}

export default HttpContext;

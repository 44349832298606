import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import { DefaultSelectorIcon } from 'components/Icons';
import PagebaleCardGrid from 'components/card-grid/CardGrid';
import { useEffect, useState } from 'react';
import StringUtil from 'utils/StringUtil';

export default function AddressCardGrid(props) {
  const { allTabData, showDetails } = props;

  function getCard(item, index) {
    return <AddressCard address={item} onEdit={showDetails} />;
  }

  return (
    <div style={{ marginTop: '10px' }}>
      <PagebaleCardGrid getCard={getCard} data={allTabData} />
    </div>
  );
}

function AddressCard(props) {
  const { address, onEdit } = props;
  const [blankLines, setBlankLines] = useState(0);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    var blankLineCount = 0;
    if (StringUtil.isBlank(address.line1)) {
      blankLineCount++;
    }
    if (StringUtil.isBlank(address.line2)) {
      blankLineCount++;
    }
    if (StringUtil.isBlank(address.line3)) {
      blankLineCount++;
    }
    if (StringUtil.isBlank(address.line4)) {
      blankLineCount++;
    }
    if (StringUtil.isBlank(address.town)) {
      blankLineCount++;
    }
    setBlankLines(blankLineCount);
    setInitialized(true);
  }, []);

  function getTrimmedAddressText(line) {
    return StringUtil.curtailWithEllipses(line, 30, true);
  }

  return (
    initialized && (
      <Grid item key={address.systemCode} xs={12} sm={4} md={3} sx={{ minWidth: '250px' }}>
        <Card variant='outlined' sx={{ borderRadius: '10px', boxShadow: '0 2px 1px 0 rgba(0,0,0,.16)' }}>
          <CardContent>
            <div style={{ display: 'flex' }}>
              <AddressHeader>{getTrimmedAddressText(address.name + ' (' + address.shortCode + ')')} </AddressHeader>
              {address.defaultAddress && (
                <>
                  &nbsp; <DefaultSelectorIcon fontSize='small' />
                </>
              )}
            </div>
            <AddressLine>{address.line1}</AddressLine>
            {address.line2 && <AddressLine>{getTrimmedAddressText(address.line2)}</AddressLine>}
            {address.line3 && <AddressLine>{getTrimmedAddressText(address.line3)}</AddressLine>}
            {address.line4 && <AddressLine>{getTrimmedAddressText(address.line4)}</AddressLine>}
            {address.town && <AddressLine>{getTrimmedAddressText(address.town)}</AddressLine>}
            <AddressLine>{getTrimmedAddressText(address.districtName + ' ' + address.provinceName + ' ' + address.postCode)}</AddressLine>
            <AddressLine>{getTrimmedAddressText(address.countryName)}</AddressLine>
            {/* Add blank lines as <p> tags */}
            {Array.from({ length: blankLines }).map((_, index) => (
              <AddressLine>&nbsp;</AddressLine>
            ))}
          </CardContent>
          <CardActions>
            <AddressCardButton onClick={() => onEdit(address)} label='Edit' />
            <AddressCardButton label='Remove' />
            {!address.defaultAddress && <AddressCardButton label='Set as Default' />}
          </CardActions>
        </Card>
      </Grid>
    )
  );
}

function AddressHeader(props) {
  const { children, ...other } = props;
  return (
    <AddressLine color='textPrimary' variant='h6' {...other}>
      {children}
    </AddressLine>
  );
}

function AddressLine(props) {
  const { children, ...other } = props;
  return (
    <div>
      <Typography variant='body1' sx={{ fontSize: '1rem', lineHeight: '1.2', color: '#0f1111' }} {...other}>
        {children}
      </Typography>
    </div>
  );
}

function AddressCardButton(props) {
  const { label, onClick } = props;

  return (
    <Button variant='text' size='small' onClick={onClick} sx={{ minWidth: '0px', textTransform: 'none', fontSize: '13px', fontWeight: '400' }}>
      {label}
    </Button>
  );
}

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { FullWidthSideDrawer } from "components/Drawer/Drawer";
import { GrapeContainer } from "components/Grapesjs/GrapeContainer";
import { DropDownMenu } from "components/Menu/Menu";
import HotlinkLoading from 'components/HotlinkLoading';
import useFileSystem from "hooks/filesystem/useFileSystem";
import { Typography } from '../../../../node_modules/@mui/joy/index';
import ReactDOM from 'react-dom';
import { RenderHtml } from 'components/html/RenderHtml';

export function HtmlBlockMenu({item, onRename}) {
    const [showHtml, setShowHtml] = useState()
    const [showPreview, setShowPreview] = useState()
    const {editObject, loadObject, loadObjects ,addMultipartObject} = useFileSystem();
    const [loading, setLoading] = useState()
    
    function publish() {
        editObject({url : "/block/html/publish", systemCode : item.systemCode, type : item.type}).then(res => setLoading(false)).catch(() => setLoading(false))
    }

    return <>
        {loading && <HotlinkLoading />}
        {showHtml && 
        <FullWidthSideDrawer anchor="right" headerTitle={item.name} open={showHtml} onClose={() => setShowHtml(false)}>
            <GrapeContainer 
                onStore={(data) => {
                    editObject({url : '/block/html/overview', systemCode : item.systemCode, type : item.type, html : data.html, css : data.css})
                }} 
                onLoad={() => loadObject({url : "/block/html/overview/" + item.systemCode, systemCode : item.systemCode, type : item.type,})}
                onAssetUpload={(files) => addMultipartObject({url : "/file/upload/pcr", parentObjectCode : item.systemCode, files : files})}
                onLoadAsset={() => loadObjects({parentObjectCode : item.systemCode})}
            />
        </FullWidthSideDrawer>
        }
        {showPreview && 
        <FullWidthSideDrawer anchor="right" headerTitle={item.name} open={showPreview} onClose={() => setShowPreview(false)}>
            <HtmlBlockPreview item={item}/>
        </FullWidthSideDrawer>
        }
            <DropDownMenu menuIcon={<MoreVertIcon />}>
                <MenuItem onClick={() => {
                    onRename(item)
                }}>Rename</MenuItem>
                <MenuItem onClick={() => setShowHtml(true)}>
                    Edit Html
                </MenuItem>
                <MenuItem onClick={() => publish()}>
                    Publish
                </MenuItem>
                <MenuItem onClick={() => setShowPreview(true)}>
                    Preview
                </MenuItem>
            </DropDownMenu>
    </>
}


function HtmlBlockPreview({ item }) {
    const [html, setHtml] = useState();
    const [css, setCss] = useState();
    const {loadObject} = useFileSystem();

    useEffect(() => {
        loadObject({url : "/block/html/live/" + item.systemCode, systemCode : item.systemCode, type: item.type}).then(res => {
                setHtml(res.overview)
                setCss(res.css)
            })
    }, [])

    useEffect(() => {
        // Create a <style> tag to inject CSS
        const styleTag = document.createElement('style');
        styleTag.innerHTML = css;

        // Append the style tag to the head of the document
        document.head.appendChild(styleTag);

        // Cleanup by removing the style tag when the component unmounts
        return () => {
            if (styleTag.parentNode) {
                styleTag.parentNode.removeChild(styleTag);
            }
        };

    }, [css])

    useEffect(() => {
        // Find all target div elements
        const divs = document.querySelectorAll('div.faq-component');

        divs.forEach((div) => {
            // Replace the div content with the React component
            ReactDOM.render(<Typography>The FAQ Component has been replaced the react Component....</Typography>, div);
        });
    });

    
   return <RenderHtml html={html} data={{
                product : {
                    name : "Iphone 17",
                },
                name : "Iphone 16",
                sku : "ip-16"
            }}/>
}

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { FormBox } from 'components/Box/Box';
import { HotGrid280 } from 'components/Containers/Grid';
import { FormInput, FormLabelledSwitch, FormSelect } from 'components/Form/FormInputs';
import { VerticalTabContainer } from 'components/object-list/vertical-tab/VerticalTabContainer';
import { AppMainBody } from 'components/ToolBar/Toolbar';
import useHttp from 'hooks/useHttp';
import SaveIcon from '@mui/icons-material/Save';
import { IconButton, MenuItem } from '@mui/material';
import StringUtil from 'utils/StringUtil';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

/**
 * A detailed information about a panel.
 */
const BASIC_DETAILS = '/cloud/drive/edit';

export function DriveDetails(props) {
  return (
    <AppMainBody classes={'no-tool-bar'}>
      <MainTab {...props} />
    </AppMainBody>
  );
}

function MainTab(props) {
  const systemCode = props.systemCode;
  const { t } = useTranslation();

  const items = [
    {
      legendTitle: t('basic'),
      legendIcon: <KeyboardArrowRightIcon />,
      itemHeaderLeftToolSet: t('basic'),
      itemHeaderRightToolSet: (
        <IconButton type='submit' form='basicSectionForm'>
          <SaveIcon />
        </IconButton>
      ),
      detail: <BasicSection systemCode={systemCode} />,
    },
  ];

  return <VerticalTabContainer classes='full-height' items={items} defaultIndex={0} />;
}

function BasicSection(props) {
  const { systemCode } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { get, post } = useHttp();

  const [drive, setDrive] = useState({ name: '', referenceCode: '', type: 'C_DRIVE' });

  useEffect(() => {
    const dataFetchUrl = systemCode ? BASIC_DETAILS + '/' + systemCode : null;
    if (systemCode) {
      get(dataFetchUrl).then(res => {
        setDrive(res);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [systemCode]);

  const validationSchema = Yup.object().shape({
    referenceCode: Yup.string().required('Reference Code is required!'),
    name: Yup.string().required('Name is required!'),
    type: Yup.string().required('Name is required!'),
  });

  const handleFormSubmit = async values => {
    setLoading(true);
    try {
      await post(BASIC_DETAILS, values);
    } finally {
      setLoading(false);
    }
  };

  const update = StringUtil.isNotBlank(systemCode);
  return (
    !loading && (
      <FormBox>
        <Formik onSubmit={handleFormSubmit} initialValues={drive} validationSchema={validationSchema}>
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <form id='basicSectionForm' onSubmit={handleSubmit}>
              <HotGrid280>
                <FormInput
                  helperText={touched.referenceCode && errors.referenceCode}
                  error={Boolean(errors.referenceCode && touched.referenceCode)}
                  name='referenceCode'
                  label={t('code')}
                  onBlur={handleBlur}
                  onValueChange={handleChange}
                  value={values.referenceCode}
                />
                <FormInput
                  helperText={touched.name && errors.name}
                  error={Boolean(errors.name && touched.name)}
                  name='name'
                  label={t('name')}
                  readOnly={update}
                  onBlur={handleBlur}
                  onValueChange={handleChange}
                  value={values.name}
                />
                <FormSelect error={Boolean(errors.type && touched.type)} label={t('type')} name='type' value={values.type} onBlur={handleBlur} onChange={handleChange}>
                  <MenuItem value='C_DRIVE'>C-Type</MenuItem>
                </FormSelect>
                <FormLabelledSwitch label={t('main')} name='mainDrive' checked={values.mainDrive} onBlur={handleBlur} onChange={handleChange} />
              </HotGrid280>
            </form>
          )}
        </Formik>
      </FormBox>
    )
  );
}

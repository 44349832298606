import Mock from "../mock";

const locationList = [
    {
      id: 1,
      name : "Delhi",
      shortCode : "DEL",
    }
];


Mock.onGet("/locations").reply(() => {
    try {    
        const payload = { locations: locationList };
        return [200, payload];
      } catch (err) {
        return [500, { message: "Internal server error" }];
      }
})

Mock.onPost("/location/save").reply(() => {
    try {    
        const payload = { locations: locationList };
        return [200, payload];
      } catch (err) {
        return [500, { message: "Internal server error" }];
      }
})

import AppsIcon from '@mui/icons-material/Apps';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import RequestPath from 'common/RequestPath';
import PanelNugget from 'components/PanelNugget/PanelNugget';
import { HorizontalTab, UiTabContext, UiTabPanel } from 'components/Tabs';
import useTheme from 'contexts/Theme/ThemeContext';
import useAppState from 'hooks/useAppState';
import useAuth from 'hooks/useAuth';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import UrlUtil from 'utils/UrlUtils';
import { ApplicationBuildNugget, CopyrightNugget, FailedLoginNugget, SuccessfulLoginNugget } from '../object-list/Nuggets/Nugget';
import './user-navigation.scss';
import StringUtil from 'utils/StringUtil';

/**
 * User Navigation component.
 *
 */
export default function UserNavigation() {
  const { user } = useAuth();
  const { userNavDisplayed, setUserNavDisplayed } = useAppState();

  return (
    <>
      <div className='usernav-photobutton usernav' title='User Nav (Alt+z)'>
        <div className='usernav-photobutton-picture' onClick={() => setUserNavDisplayed(!userNavDisplayed)}>
          <img alt={user.name} src={UrlUtil.pictureURL ? UrlUtil.getCompleteUrl(user.pictureURL) : '/assets/i/directory/unavailable.jpg'} />
        </div>
      </div>
      <div className={userNavDisplayed ? 'usernavmenu usernavmenushow' : 'usernavmenu usernavmenuhide'}>
        <UserNavigationBackDrop />
        <UserNavigationConsole />
      </div>
    </>
  );
}

/**
 * A backdrop for user navigation.
 */
export function UserNavigationBackDrop() {
  return <div className='usernavmenu-backdrop'>&nbsp;</div>;
}

/**
 * User navigation console.
 */
export function UserNavigationConsole(props) {
  return (
    <div className='usernavconsole'>
      <UserNavigationConsoleHeader />
      <UserNavigationConsoleBox />
      <UserNavigationFooter />
    </div>
  );
}

/**
 * User navigation header.
 */
export function UserNavigationConsoleHeader(props) {
  const { user, logout } = useAuth();
  const { t } = useTranslation();

  const [sideDrawerDisplayed, setSideDrawerDisplayed] = useState(false);
  const { userNavDisplayed, setUserNavDisplayed, setPanelMenuDisplayed } = useAppState();

  return (
    user && (
      <>
        <div className='usernavconsole-header'>
          <div className='usernavcard-myinfo'>
            <div className='usernavcard-picture'>
              <img alt={user.name} src={user.pictureURL ? UrlUtil.getCompleteUrl(user.pictureURL) : 'assets/i/directory/unavailable.jpg'} title={user.name} />
            </div>
            <div className='usernavcard-matter'>
              <div
                onClick={() => {
                  setSideDrawerDisplayed(true);
                  setUserNavDisplayed(true);
                }}
                style={{ cursor: 'pointer' }}>
                {user.name}
              </div>
              {StringUtil.isNotBlank(user.title) && StringUtil.isNotBlank(user.organization) && (
                <div>
                  {user.title} at {user.organization}
                </div>
              )}
              <div className='usernavheader-links'>
                <span className='panelnav hyperlink' onClick={() => setPanelMenuDisplayed(true)}>
                  <span>Panel Nav</span>
                </span>
                <span>|</span>
                <span className='hyperlink'>
                  <span onClick={logout} title='Sign Out'>
                    {t('sign-out')}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className='usernavconsole-close usernav' title='Esc / Alt+z' onClick={() => setUserNavDisplayed(!userNavDisplayed)}>
            <i className='fas fa-times-circle'>&nbsp;</i>
          </div>
        </div>
      </>
    )
  );
}

export function UserNavigationConsoleBox(props) {
  const { t } = useTranslation();
  const { changeTheme } = useTheme();

  const tabs = [
    <HorizontalTab label={t('main')} value='userNavMain' key='userNavMain' />,
    <HorizontalTab label={t('session')} value='userNavSession' key='userNavSession' />,
    <HorizontalTab label={t('theme')} value='theme' key='theme' />,
  ];

  const tabList = (
    <UiTabContext tabs={tabs} defaultTab={'userNavMain'}>
      <UiTabPanel value='userNavMain'>
        <QuickLinks />
        <PanelNugget />
      </UiTabPanel>
      <UiTabPanel value='userNavSession'>
        <SuccessfulLoginNugget />
        <FailedLoginNugget />
        <ApplicationBuildNugget />
        <CopyrightNugget />
      </UiTabPanel>
      <UiTabPanel value='theme'>
        <ListItemButton onClick={() => changeTheme('compact')}>
          <ListItemIcon>
            <ViewCompactIcon />
          </ListItemIcon>
          <ListItemText primary={'Compact'} />
        </ListItemButton>
        <ListItemButton onClick={() => changeTheme('comfort')}>
          <ListItemIcon>
            <AppsIcon />
          </ListItemIcon>
          <ListItemText primary={'Comfortable'} />
        </ListItemButton>
      </UiTabPanel>
    </UiTabContext>
  );

  return (
    <div className='usernavbox'>
      <div className='usernavboxheader'>
        <div className='usernavboxmenuclick usernavboxmenuhide'>
          <i className='fas fa-bars'>&nbsp;</i>
        </div>
        <div className='usernavboxsearch'>Search Platform</div>
        <div className='usernavboxsearchclick'>
          <i className='fas fa-search'>&nbsp;</i>
        </div>
      </div>
      <div className='usernavboxframe'>
        <div className='usernavbox-container'>{tabList}</div>
      </div>
    </div>
  );
}

export function UserNavigationFooter() {
  const { t } = useTranslation();
  const { logout } = useAuth();

  return (
    <div className='usernavconsole-footer'>
      <a href={UrlUtil.getCompleteUrl('/help')} title={t('help')} target='_blank' rel='noreferrer'>
        {t('help')}
      </a>
      <a href={UrlUtil.getCompleteUrl('/privacy')} title={t('privacy')} target='_blank' rel='noreferrer'>
        {t('privacy')}
      </a>
      <a href={UrlUtil.getCompleteUrl('/uptime')} target='_blank' rel='noreferrer' title={t('availability')}>
        {t('availability')}
      </a>
      <a href={UrlUtil.getCompleteUrl('/contact')} target='_blank' rel='noreferrer' title={t('contact')}>
        {t('contact')}
      </a>
      <a className='usernavfooter-signout' onClick={logout} title='Sign Out'>
        {t('sign-out')} <i className='fa fa-power-off' aria-hidden='true'></i>
      </a>
    </div>
  );
}

export function QuickLinks() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className='usernavnugget-separator' data-toggle-initialized='true'>
        {t('quick-links')}
      </div>
      <div className='usernav-quicklinks'>
        <div>
          <a href={'/account/apply'} target='_blank' rel='noreferrer'>
            <span>
              <i className='fa fa-asterisk' aria-hidden='true'></i>
            </span>
            <span>{t('create-business-account')}</span>
          </a>
        </div>
        <div>
          <span>
            <span>
              <i className='fa fa-gift' aria-hidden='true'></i>
            </span>
            <span>{t('invite-companies')}</span>
          </span>
        </div>
        <div>
          <a href={UrlUtil.getCompleteUrl('/people/invitation')} target='_blank' rel='noreferrer'>
            <span>
              <i className='fa fa-users' aria-hidden='true'></i>
            </span>
            <span>{t('invite-professionals')}</span>
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}

import { AppMainMenuTree } from "components/Menu/AppMainMenuList";
import StringUtil from "utils/StringUtil";

export function AppMainTitleBar(props) {
    const { title, fixed, trackMenu, onClose, rightIcons, buttonSectionId } = props

    function handleClose(e) {
        if (onClose && typeof (onClose) === 'function') {
            onClose(e);
        }
    }

    const menuAnchor = <div className="appmain-menu-button top-show">
                            <span><i className="fas fa-bars" aria-hidden="true">&nbsp;</i></span>
                        </div>

    return (
        <div className="appmain-titlebar">
            {trackMenu && <AppMainMenuTree menu={trackMenu} menuAnchor={menuAnchor}/>}
            <div className="appmain-backbutton top-show" onClick={handleClose}>
                <span><i className="fas fa-arrow-left" aria-hidden="true">&nbsp;</i></span>
            </div>
            <div className="appmain-title">{title}</div>
            <div className="appmain-title" style={{ display: 'flex', justifyContent: 'flex-end' }} id={buttonSectionId || StringUtil.getHtmlValidId(title)}>
                {/* {CollectionUtil.isNotBlank(rightIcons) &&  rightIcons.map((icon, index) => {return icon}) }
                {
                    !fixed &&
                    <AppMainTitleIcon onClick={() => appState.setAppFeedDisplayed(!appState.appFeedDisplayed)}> </AppMainTitleIcon>
                } */}
            </div>
        </div>
    )
}

export function AppMainTitleIcon({onClick, children}) {
    return <span className="appmainoptionsclick new-tab-link" onClick={onClick}>
        {children}
    </span>
}

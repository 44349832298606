import { HoverLink } from 'components/Link';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Box, Collapse, IconButton, List, ListItemButton, ListItemText } from '@mui/material';
import { useEffect, useState } from 'react';
import useAppState from 'hooks/useAppState';
import { headerHeight, navigationPaneWidth } from './constants';
import { useNavigate } from 'react-router-dom';
import StringUtil from '../../../utils/StringUtil';
import { Outlet } from 'react-router-dom';

export function Body({ children }) {
  const [currentView, setCurrentView] = useState(null);
  const { application, appMenuDisplayed, applicationMenu, setAppMenuDisplayed } = useAppState();

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Escape') {
        setCurrentView(null);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  /* Middle Column */
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mt: headerHeight,
        flexGrow: 1,
      }}>
      {/* Content Area */}
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        {/* Navigation Pane */}
        {appMenuDisplayed && (
          <Box
            sx={{
              width: navigationPaneWidth,
              bgcolor: 'rgba(0, 0, 0, 0.65)',
              height: 'calc(100vh - ' + headerHeight + ')',
              overflowY: 'auto',
              p: 2,
              color: 'white',
            }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className='directory-menu'>
              <div style={{ marginLeft: '10px', textTransform: 'uppercase' }}>
                <HoverLink>{application && StringUtil.nullSafe(application.name)}</HoverLink>
              </div>
              <div style={{ marginLeft: 'auto' }}>
                <IconButton sx={{ color: '#FFF' }} onClick={() => setAppMenuDisplayed(false)}>
                  {' '}
                  <ChevronLeftIcon />{' '}
                </IconButton>
              </div>
            </div>
            {application && application.component ? (
              application.component
            ) : (
              <Box sx={{ height: '100%' }}>
                {applicationMenu.map((item, index) => (
                  <ModuleItem item={item} key={index} setCurrentView={setCurrentView} />
                ))}
              </Box>
            )}
          </Box>
        )}

        {/* Main Content */}
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: currentView ? 'transparent' : 'rgba(255, 255, 255, 0)',
            height: 'calc(100vh - ' + headerHeight + ')',
          }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

export function ModuleItem({ item, sx, setCurrentView }) {
  const [open, setOpen] = useState(false);
  const [children, setChildren] = useState();
  const navigate = useNavigate();
  const handleClick = () => {
    if (item.children) {
      setOpen(!open);
    } else if (item.url) {
      navigate(item.url);
      setCurrentView(item);
    }
  };

  useEffect(() => {
    setChildren(item.children && item.children.length > 0);
  }, [item]);

  return (
    <>
      <ListItemButton onClick={handleClick} sx={sx}>
        {/* <ListItemIcon>
                    <InboxIcon />
                </ListItemIcon> */}
        <ListItemText primary={item.name} />
        {children ? open ? <ExpandLess /> : <ExpandMore /> : <></>}
      </ListItemButton>
      {children && (
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {item.children?.map((child, index) => (
              <ModuleItem item={child} sx={{ pl: 4 }} key={index} setCurrentView={setCurrentView} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}

import { Box, List, Typography } from "@mui/material";
import { useState } from "react";
import { PropertiesFirstColumn } from "./first-column";
import { MenuItem } from "./menu-item";
import { PropertiesSecondColumn } from "./second-column";
import { menuPadding } from "./constants";

export function Properties({menu, title, colorCode}) {
  // State for dynamic interactivity
  const [activeSidebarOption, setActiveSidebarOption] = useState(menu[0]);
  const [activeSubmenuOption, setActiveSubmenuOption] = useState();
  return (
    <Box sx={{ height: "100vh" }}>
        {/* Main Layout */}
        <Box sx={{ display : 'flex', height : '100%'}}>
            <PropertiesFirstColumn title={title}>
                <List>
                    {menu.map((option) => (
                        <MenuItem 
                            colorCode={colorCode}
                            item={option}
                            key={option.name} 
                            selectedItem={activeSidebarOption}
                            onClick={(item) => setActiveSidebarOption(item)}
                        />
                    ))}
                </List>
            </PropertiesFirstColumn>
            <PropertiesSecondColumn title={activeSidebarOption.name}>
                <List>
                    {activeSidebarOption.nextLevel?.map((option) => (
                        <MenuItem 
                            colorCode={colorCode}
                            item={option} 
                            key={option.name}  
                            selectedItem={activeSubmenuOption}
                            onClick={(item) => setActiveSubmenuOption(item)}
                        />
                    ))}
                </List>
            </PropertiesSecondColumn>
            {/* Third Column: Details Pane */}
            <Box sx={{ backgroundColor: "#fff", padding: menuPadding,flexGrow : 1}}>
                <Typography
                    variant="h6"
                    sx={{
                    mb: menuPadding,
                    fontSize: "1.25rem",
                    fontWeight: "bold",
                    color: "#333",
                    }}
                >
                    {activeSubmenuOption?.name}
                </Typography>
                {activeSubmenuOption?.component}
            </Box>
        </Box>
    </Box>
  )
}
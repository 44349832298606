import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import HtmlIcon from '@mui/icons-material/Html';
import SaveIcon from '@mui/icons-material/Save';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WebIcon from '@mui/icons-material/Web';
import { Avatar, Box, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import CopyToClipboard from 'components/CopyToClipboard';
import BusinessIcon from '@mui/icons-material/Business';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import HomeIcon from '@mui/icons-material/Home';
import ImageIcon from '@mui/icons-material/Image';
import StorefrontIcon from '@mui/icons-material/Storefront';
import StarIcon from '@mui/icons-material/Star';
import StringUtil from 'utils/StringUtil';

const CSVFileIconImage = '/assets/i/filetype/pngegg-CSV.png';
const ExcelFileIconImage = '/assets/i/filetype/pngegg-Excel.png';
const PDFFileIconImage = '/assets/i/filetype/pngegg-PDF.png';
const PPTFileIconImage = '/assets/i/filetype/pngegg-Power Point.png';
const WordFileIconImage = '/assets/i/filetype/pngegg-Word.png';

export function DefaultSelectorIcon(props) {
  return <StarIcon color='primary' {...props} />;
}

export function AppImageIcon(props) {
  return <ImageIcon />;
}

export function AppBusinessCenterIcon(props) {
  return <BusinessCenterIcon />;
}

export function AppBusinessIcon(props) {
  return <BusinessIcon />;
}

export function StoreIcon(props) {
  return <StorefrontIcon />;
}

export function AppHomeIcon() {
  return <HomeIcon />;
}

export function PreviewIcon(props) {
  return (
    <Tooltip title='Preview'>
      <VisibilityIcon />
    </Tooltip>
  );
}

export function CopyIcon(props) {
  //size can be small, medium or large
  const { size, value } = props;

  return (
    <CopyToClipboard value={value}>
      <Tooltip title='Copy'>
        <i className='fa fa-copy'></i>
      </Tooltip>
    </CopyToClipboard>
  );
}

export function FormwardArrowIcon(props) {
  return <ArrowForwardIcon />;
}

export function AttachmentIcon(props) {
  return <FilePresentIcon />;
}

export function PortalIcon(props) {
  return <CorporateFareIcon />;
}

export function ExcelFileIcon(props) {
  return <Thumbnail alt='Download' src={ExcelFileIconImage} title='Download' {...props} />;
}

export function WordFileIcon(props) {
  return <Thumbnail alt='Download' src={WordFileIconImage} title='Download' {...props} />;
}

export function PDFFileIcon(props) {
  return <Thumbnail alt='Download' src={PDFFileIconImage} title='Download' {...props} />;
}

export function CSVFileIcon(props) {
  return <Thumbnail alt='Download' src={CSVFileIconImage} title='Download' {...props} />;
}

export function PPTFileIcon(props) {
  return <Thumbnail alt='Download' src={PPTFileIconImage} title='Download' {...props} />;
}

export function Thumbnail(props) {
  const { size } = props;
  return (
    <>
      {size === 'MEDIUM' && (
        <figure style={{ width: '40px', height: '40px' }}>
          {' '}
          <img {...props} />{' '}
        </figure>
      )}
      {size === 'SMALL' && (
        <figure style={{ width: '1em', height: '1em' }}>
          {' '}
          <img {...props} />{' '}
        </figure>
      )}
    </>
  );
}

export function FormSaveIcon(props) {
  return <SaveIcon />;
}

export function FileIcon(props) {
  const { fileType, size, onClick } = props;
  const fileTypeIconMap = new Map();
  const iconSize = size ? size : 'SMALL';
  fileTypeIconMap.set('EXCEL', <ExcelFileIcon size={iconSize} />);
  fileTypeIconMap.set('DOC', <WordFileIcon size={iconSize} />);
  fileTypeIconMap.set('PDF', <PDFFileIcon size={iconSize} />);
  fileTypeIconMap.set('CSV', <CSVFileIcon size={iconSize} />);
  fileTypeIconMap.set('PPT', <PPTFileIcon size={iconSize} />);
  fileTypeIconMap.set('HTML_BLOCK', <HtmlIcon size={iconSize} />);
  fileTypeIconMap.set('PAGE_ARTIFACT', <WebIcon size={iconSize} />);
  fileTypeIconMap.set('PORTAL', <PortalIcon size={iconSize} />);
  fileTypeIconMap.set('ATTACHMENT', <AttachmentIcon size={iconSize} />);

  return (
    <>
      <div onClick={onClick ? onClick : () => {}}>{StringUtil.isNotBlank(fileType) && fileTypeIconMap.get(fileType)}</div>
    </>
  );
}

export function ThumbUpIcon(props) {
  const theme = useTheme();
  return <ThumbUpAltIcon sx={{ color: theme.palette.success.main }} />;
}

export function ThumbDownIcon(props) {
  const theme = useTheme();
  return <ThumbDownAltIcon sx={{ color: theme.palette.error.main }} />;
}

export function CircleLogo(props) {
  const { image, iconId, onIconClick, onArrowClick } = props;
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 40,
        height: 40,
        backgroundColor: '#f0f4e2', // Background color similar to the logo in the image
        borderRadius: '50%',
        cursor: 'pointer',
      }}
      id={iconId || 'logo-with-icon'}
      onClick={onIconClick}>
      {/* Circular logo */}
      <Avatar
        sx={{
          width: 40,
          height: 40,
          fontSize: '14px',
        }}>
        {image}
      </Avatar>
    </Box>
  );
}

export function CircleLogoWithArrow(props) {
  const { image, iconId, onIconClick, onArrowClick } = props;
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 40,
        height: 40,
        backgroundColor: '#f0f4e2', // Background color similar to the logo in the image
        borderRadius: '50%',
        cursor: 'pointer',
      }}
      id={iconId || 'logo-with-icon'}
      onClick={onIconClick}>
      {/* Circular logo */}
      <Avatar
        sx={{
          width: 40,
          height: 40,
          fontSize: '14px',
        }}>
        {image}
      </Avatar>

      {/* Dropdown arrow icon */}
      <IconButton
        sx={{
          position: 'absolute',
          bottom: -10, // Adjust based on your spacing preference
          right: -20, // Adjust based on your spacing preference
          p: 0,
        }}
        onClick={e => {
          onArrowClick();
          e.stopPropagation();
        }}>
        <ArrowDropDownIcon sx={{ fontSize: 30, color: 'white' }} />
      </IconButton>
    </Box>
  );
}

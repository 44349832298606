export function RenderHtml({html, data}) {
    // Process the template
    const renderHtml = () => {
        if(html) {
            // Replace placeholders in the format {{key}} with corresponding values from the data object
            return html.replace(/{{\s*([\w.]+)\s*}}/g, (match, key) => {
                // Support nested keys (e.g., "user.name")
                const value = key.split('.').reduce((acc, curr) => acc && acc[curr], data);
                return value !== undefined ? value : match; // If key not found, keep the placeholder
            });
        }
    };
    // Process the template
    const processedHtml = renderHtml();

    return html && <div dangerouslySetInnerHTML={{ __html: processedHtml }}></div>
}
import { Breadcrumbs, Toolbar } from '@mui/material';
import StringUtil from 'utils/StringUtil';
import './toolbar.scss';
import { CollectionUtil } from 'utils/CollectionUtil';
import { SmartChip } from 'components/Chip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { ScreenUtil } from 'utils/ScreenUtil';

export function AppMainToolBar(props) {
    return (
        <div className="appmain-toolbar">
            <div className="appmain-toolset">
                {props.children}
            </div>
        </div>
    )
}


export function AppMainTrackBreadCrumbBar(props) {
    const {breadCrumbItems, ...others} = props;

    // Custom styled Breadcrumb item
    const CustomBreadcrumbItem = styled(Typography)({
        marginLeft: 0, // Remove left margin
    });

    function getMaximumItemInBreadCrumb() {
        if(ScreenUtil.isMobile()) {
            return 3;
        } else if(ScreenUtil.isTablet()) {
            return 5;
        }
        return 6;
    }

    return (
        CollectionUtil.isNotBlank(breadCrumbItems) && <AppMainToolBar>
            <div className="appmain-toolbar" style={{marginTop:"5px"}}>
                <Breadcrumbs maxItems={getMaximumItemInBreadCrumb()} separator="›" aria-label="breadcrumb" sx={{
                        '& .MuiBreadcrumbs-li' : {ml:"10px"}
                    }}>
                    { 
                        breadCrumbItems.map(bc => <CustomBreadcrumbItem key={bc.label}> <span key={bc.label}>{bc.label}</span></CustomBreadcrumbItem>)
                    }
                </Breadcrumbs>
            </div>
        </AppMainToolBar>
    )
}

export function AppMainBreadCrumbBar(props) {
    const {breadCrumbItems, ...others} = props;
    return (
        CollectionUtil.isNotBlank(breadCrumbItems) && <div className="appmain-toolbar">
            <Breadcrumbs maxItems={2} separator="›" aria-label="breadcrumb">
                { 
                    breadCrumbItems.map(bc => <SmartChip label={bc.label} key={bc.label} />)
                }
            </Breadcrumbs>
        </div>
    )
}

export function OverlayedAppMainBody(props) {
    return (
        <AppMainBody {...props} containerStyle={{overflowY:"overlay"}} />
    )
}

export function AppMainBodyNoVertScroll(props) {
    return (
        <AppMainBody {...props} disableYScroll/>
    )
}

export function InternalAppMainBody(props) {
        return <AppMainBody {...props} containerClasses="level2-internal no-vert-scrollbar" fsx={{height:"auto"}}/>
}

export function AppMainBody(props) {
    const {classes, containerClasses, disableYScroll, internal, fsx, containerStyle, onScroll, ...others} = props
    return <div className={"appmain-frame " + StringUtil.nullSafe(classes)} style={{...fsx}} {...others}>
        {disableYScroll
            ? <div className={"appmain-container no-y-scroll " + StringUtil.nullSafe(containerClasses) } style={{...containerStyle}}> {props.children} </div>
            : <div className={"appmain-container " + StringUtil.nullSafe(containerClasses) } style={{...containerStyle}} onScroll={onScroll}> {props.children} </div>}
    </div>
}

export function LeftAlignedToolBar(props) {
    return (
        <ToolBar leftToolSet={props.children} />
    )
}

export function ToolBar(props) {
    const {leftToolSet, rightToolSet} = props;
    return (
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }} className="toolbar" >
            <div className="left-tool-set">
                {leftToolSet}
            </div>
            <div className="right-tool-set">
                {rightToolSet}
            </div>
        </div>
    )
}
import { Alert, Snackbar } from '@mui/material';
import { createContext, useState } from 'react';
import StringUtil from 'utils/StringUtil';

const initialState = {
  errors: null,
  setAppErrors: () => {},
};

export const ErrorSuccessContext = createContext({
  ...initialState,
});

export const ErrorContextProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  const setAppErrors = buffer => {
    setState(prevState => {
      return { ...prevState, errors: buffer };
    });
  };

  const removeError = error => {
    const buffer = state.errors.filter(function (item) {
      return item !== error;
    });

    setAppErrors(buffer);
  };

  return (
    <ErrorSuccessContext.Provider value={{ ...state, setAppErrors }}>
      {state.errors && state.errors.map((error, index) => <ErrorSnackbar error={error} onClose={() => removeError(error)} key={index} />)}
      {children}
    </ErrorSuccessContext.Provider>
  );
};

function ErrorSnackbar({ error, onClose }) {
  return (
    <Snackbar open={true} onClose={onClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
      <Alert onClose={onClose} severity='error' sx={{ width: '100%' }}>
        {error.message}
      </Alert>
    </Snackbar>
  );
}

export function SuccessSnackbar({ successMessage, onClose }) {
  const [open, setOpen] = useState(true);
  return (
    <Snackbar open={open} onClose={() => setOpen(false)} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
      <Alert onClose={onClose} severity='success' sx={{ width: '100%' }}>
        {successMessage || 'Success'}
      </Alert>
    </Snackbar>
  );
}


import useHttp from "hooks/useHttp";
import MenuService from "service/MenuService";
import StringUtil from "utils/StringUtil";
import useAuth from "hooks/useAuth";
import { createContext, useEffect, useState } from "react";
// CUSTOM COMPONENT

const initialState = {
  application: null,
  applicationMenu: [],
  appMenuDisplayed: null,
  appNavDisplayed: null,
  appBarDisplayed: null,
  detailsDisplayed: null,
  userNavDisplayed: null,
  panelMenuDisplayed: false,
  identities: {},
  panels: {},
  currentPanel: {},
  setCurrentPanel: () => { },
  getActiveIdentity: () => { },
  setDetailsDisplayed: () => { }
};

const AppStateContext = createContext({
  ...initialState,
  setUserNavDisplayed: () => { },
  setPanelMenuDisplayed: () => { }
});

export const AppStateProvider = ({ children }) => {
  const [state, setState] = useState(initialState)
  const { user } = useAuth();
  const {get} = useHttp();

  const getApplicationMenu = () => {
    return state.applicationMenu;
  }

  const hasAnyPermission = (permissions) => {
    if (permissions && permissions.length > 0) {
      return permissions?.filter(permission => hasPermission(permission)).length > 0;
    }

    return false
  }

  const hasPermission = (permission) => {
    return permission && state.currentPanel?.authorities?.filter(a => StringUtil.equalsIgnoreCase(a.permission, permission)).length > 0
  }


  const setDetailsDisplayed = (flag) => {
    setState((prevState) => {
      return { ...prevState, detailsDisplayed: flag }
    })
  };

  const setUserNavDisplayed = (flag) => {
    setState((prevState) => {
      return { ...prevState, userNavDisplayed: flag }
    })
  };

  const setPanelMenuDisplayed = (flag) => {
    setState((prevState) => {
      return { ...prevState, panelMenuDisplayed: flag }
    })
  };

  const setApplication = (app) => {
    if (app && app.menu) {
      MenuService.loadMenu(app.menu).then((appMenu) => {
        setState((prevState) => {
          return {...prevState, application : app, applicationMenu : appMenu}
        })
      });
    }

    else {
      setState((prevState) => {
        return { ...prevState, application: app }
      })
    }
  };

  const setAppMenuDisplayed = (flag) => {
    setState((prevState) => {
      return { ...prevState, appMenuDisplayed: flag }
    })
  };

  const setAppNavDisplayed = (flag) => {
    setState((prevState) => {
      return { ...prevState, appNavDisplayed: flag }
    })
  };

  const setAppBarDisplayed = (flag) => {
    setState((prevState) => {
      return { ...prevState, appBarDisplayed: flag }
    })
  };

  const setCurrentPanel = (panel) => {
    setState((prevState) => {
      return { ...prevState, currentPanel: panel }
    })
  };

  const updateIdentities = (buffer) => {
    setState((prevState) => {
      const { identities } = prevState;

      buffer.forEach(element => {
        identities[element.code] = element
      });

      return { ...prevState, identities: identities, panels: identities }
    })
  };

  const getActiveIdentity = () => {
    const { identities } = state;

    return identities[user?.activeIdentityCode]
  };

  useEffect(() => {
    loadContext();
  }, [user])

  /**
   * Updates the information about the active panel.
  */
  function loadContext() {
    if (user) {
      get("/context").then(identities => {
        updateIdentities(identities)

        setCurrentPanel(identities.filter(i => i.code == user.activeIdentityCode)[0])
      });
    }
  }

  return (
    <AppStateContext.Provider value={{ ...state, getApplicationMenu, hasAnyPermission, hasPermission, setApplication, setAppBarDisplayed, setAppMenuDisplayed, setAppNavDisplayed, setUserNavDisplayed, setPanelMenuDisplayed, getActiveIdentity, setCurrentPanel, setDetailsDisplayed }}>
      {children}
    </AppStateContext.Provider>
  );
};

export default AppStateContext;

import { MenuItem } from '@mui/material';
import { FormBoxWithToolBar } from 'components/Box/Box';
import { HotGrid280 } from 'components/Containers/Grid';
import { FormInput, FormLabelledSwitch, FormSelect } from 'components/Form/FormInputs';
import { StandardAccordian } from 'components/accordian/Accordian';
import { Formik } from 'formik';
import useHttp from 'hooks/useHttp';
import { add } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberUtil } from 'utils/NumberUtil';
import StringUtil from 'utils/StringUtil';
import * as Yup from 'yup';

const BASIC_DETAILS = '/account/address/edit';

export function AddressDetail(props) {
  const { systemCode } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [countries, setCountries] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const { get, post } = useHttp();

  const initalState = {
    name: 'Address - ' + NumberUtil.randomInt(1000),
    shortCode: StringUtil.getRandomString(4).toUpperCase(),
    defaultAddress: false,
    line1: 'Plot Number -' + NumberUtil.randomInt(1000),
    line2: '',
    line3: '',
    line4: '',
    countryCode: 'IN',
    provinceCode: 'IN-HR',
    districtCode: 'IN-HR-05',
    town: '',
    postCode: '122002',
    latitude: '',
    longitude: '',
  };

  const [address, setAddress] = useState(initalState);

  const loadCountries = async () => {
    get('/location/countries').then(data => setCountries(data));
  };

  async function loadProvince(country) {
    get('/location/provinces/' + country).then(data => setProvinces(data));
  }

  async function loadDistricts(province) {
    get('/location/districts/' + province).then(data => setDistricts(data));
  }

  useEffect(() => {
    const dataFetchUrl = systemCode ? BASIC_DETAILS + '/' + systemCode : null;
    loadCountries();
    if (StringUtil.isNotBlank(address.countryCode)) {
      loadProvince(address.countryCode);
    }
    if (StringUtil.isNotBlank(address.provinceCode)) {
      loadDistricts(address.provinceCode);
    }
    if (systemCode) {
      get(dataFetchUrl).then(res => {
        setAddress(res);
        setLoading(false);
        if (StringUtil.isNotBlank(res.countryCode)) {
          loadProvince(res.countryCode);
        }
        if (StringUtil.isNotBlank(res.provinceCode)) {
          loadDistricts(res.provinceCode);
        }
      });
    } else {
      setLoading(false);
    }
  }, [systemCode]);

  const validationSchema = Yup.object().shape({
    shortCode: Yup.string().required('Short Code is required!'),
    name: Yup.string().required('Name is required!'),
    line1: Yup.string().required('Line1 is required!'),
    countryCode: Yup.string().required('Country is required!'),
    provinceCode: Yup.string().required('Province is required!'),
    districtCode: Yup.string().required('District is required!'),
    postCode: Yup.number().required('Post Code is required!'),
    latitude: Yup.number().nullable(),
    longitude: Yup.number().nullable(),
  });

  const handleFormSubmit = async values => {
    setLoading(true);
    try {
      await post(BASIC_DETAILS, values).then(() => {
        setLoading(false);
        setAddress(values);
      });
    } finally {
      setLoading(false);
    }
  };

  const update = StringUtil.isNotBlank(systemCode);
  return (
    !loading && (
      <FormBoxWithToolBar>
        <Formik onSubmit={handleFormSubmit} initialValues={address} validationSchema={validationSchema}>
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <form id='submission-form' onSubmit={handleSubmit}>
              <StandardAccordian title='Basic' open>
                <HotGrid280>
                  <FormInput
                    fullWidth
                    showInputBox
                    helperText={touched.shortCode && errors.shortCode}
                    error={Boolean(errors.shortCode && touched.shortCode)}
                    name='shortCode'
                    label={t('shortCode')}
                    onBlur={handleBlur}
                    onValueChange={handleChange}
                    value={values.shortCode}
                    maxLength={4}
                  />
                  <FormInput fullWidth showInputBox helperText={touched.name && errors.name} error={Boolean(errors.name && touched.name)} name='name' label={t('name')} onBlur={handleBlur} onValueChange={handleChange} value={values.name} />
                </HotGrid280>
                <HotGrid280>
                  <FormLabelledSwitch label={t('default')} name='defaultAddress' checked={values.defaultAddress} onBlur={handleBlur} onChange={handleChange} />
                </HotGrid280>
              </StandardAccordian>

              <StandardAccordian title='Address' open>
                <HotGrid280>
                  <FormInput
                    fullWidth
                    showInputBox
                    size='small'
                    helperText={touched.line1 && errors.line1}
                    error={Boolean(errors.line1 && touched.line1)}
                    name='line1'
                    label={t('line1')}
                    onBlur={handleBlur}
                    onValueChange={handleChange}
                    value={values.line1}
                  />
                  <FormInput
                    fullWidth
                    showInputBox
                    size='small'
                    helperText={touched.line2 && errors.line2}
                    error={Boolean(errors.line2 && touched.line2)}
                    name='line2'
                    label={t('line2')}
                    onBlur={handleBlur}
                    onValueChange={handleChange}
                    value={values.line2}
                  />
                </HotGrid280>
                <HotGrid280>
                  <FormInput
                    fullWidth
                    showInputBox
                    size='small'
                    helperText={touched.line3 && errors.line3}
                    error={Boolean(errors.line3 && touched.line3)}
                    name='line3'
                    label={t('line3')}
                    onBlur={handleBlur}
                    onValueChange={handleChange}
                    value={values.line3}
                  />
                  <FormInput
                    fullWidth
                    showInputBox
                    size='small'
                    helperText={touched.line4 && errors.line4}
                    error={Boolean(errors.line4 && touched.line4)}
                    name='line4'
                    label={t('line4')}
                    onBlur={handleBlur}
                    onValueChange={handleChange}
                    value={values.line4}
                  />
                </HotGrid280>
                <HotGrid280>
                  <FormSelect
                    fullWidth
                    helperText={touched.countryCode && errors.countryCode}
                    error={Boolean(errors.countryCode && touched.countryCode)}
                    name='countryCode'
                    label={t('country')}
                    onBlur={handleBlur}
                    onChange={e => {
                      handleChange(e);
                      loadProvince(e.target.value);
                    }}
                    value={values.countryCode}>
                    {countries.map(country => {
                      return (
                        <MenuItem value={country.isoAlpha2Code} key={country.isoAlpha2Code}>
                          <em>{country.name}</em>
                        </MenuItem>
                      );
                    })}
                  </FormSelect>

                  <FormSelect
                    fullWidth
                    helperText={touched.provinceCode && errors.provinceCode}
                    error={Boolean(errors.provinceCode && touched.provinceCode)}
                    name='provinceCode'
                    label='State'
                    onBlur={handleBlur}
                    onChange={e => {
                      handleChange(e);
                      loadDistricts(e.target.value);
                    }}
                    value={values.provinceCode}>
                    {provinces.map(province => {
                      return (
                        <MenuItem value={province.referenceCode}>
                          <em>{province.name}</em>
                        </MenuItem>
                      );
                    })}
                  </FormSelect>
                </HotGrid280>
                <HotGrid280>
                  <FormSelect
                    fullWidth
                    helperText={touched.districtCode && errors.districtCode}
                    error={Boolean(errors.districtCode && touched.districtCode)}
                    name='districtCode'
                    label='District'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.districtCode}>
                    {districts.map(district => {
                      return (
                        <MenuItem value={district.referenceCode}>
                          <em>{district.name}</em>
                        </MenuItem>
                      );
                    })}
                  </FormSelect>
                  <FormInput
                    fullWidth
                    showInputBox
                    size='small'
                    helperText={touched.town && errors.town}
                    error={Boolean(errors.town && touched.town)}
                    name='town'
                    label={t('town')}
                    onBlur={handleBlur}
                    onValueChange={handleChange}
                    value={values.town}
                  />
                </HotGrid280>
                <HotGrid280>
                  <FormInput
                    fullWidth
                    showInputBox
                    size='small'
                    helperText={touched.postCode && errors.postCode}
                    error={Boolean(errors.postCode && touched.postCode)}
                    name='postCode'
                    label={t('postCode')}
                    onBlur={handleBlur}
                    onValueChange={handleChange}
                    value={values.postCode}
                  />
                </HotGrid280>
                <HotGrid280>
                  <FormInput
                    fullWidth
                    size='small'
                    helperText={touched.latitude && errors.latitude}
                    error={Boolean(errors.latitude && touched.latitude)}
                    name='latitude'
                    label={t('latitude')}
                    onBlur={handleBlur}
                    onValueChange={handleChange}
                    value={values.latitude}
                  />
                  <FormInput
                    fullWidth
                    size='small'
                    helperText={touched.longitude && errors.longitude}
                    error={Boolean(errors.longitude && touched.longitude)}
                    name='longitude'
                    label={t('longitude')}
                    onBlur={handleBlur}
                    onValueChange={handleChange}
                    value={values.longitude}
                  />
                </HotGrid280>
              </StandardAccordian>
            </form>
          )}
        </Formik>
      </FormBoxWithToolBar>
    )
  );
}

import { createContext, useState } from "react";
import { ObjectUtil } from "utils/ObjectUtil";
import useHttp from "hooks/useHttp";
import { useParams } from "react-router-dom";

const initialState = {
    familyId: null,
    familyHeadType: null,
    familyHeadCode : null,
    parentType : null,
    parentSystemCode : null,
    familyHeadScope : null,
    driveCode : null,
    currentDirectory : null,
};


export const FileSystemContext = createContext({
    ...initialState,
});

export const FileSystemProvider = ({ children }) => {
    const [state, setState] = useState(initialState);
    const [breadcrumbs, setBreadCrumbs] = useState([]);
    const {get, post, postMultipart} = useHttp();
    const {driveCode} = useParams();

    const updateFieldValue = (field, value) => {
        setState((prevState) => {
            return {...prevState, [field] : value}
        })
    }

    function checkIfObjectTypeExist(data) {
        if(!data.type) {
            throw new Error("Type is not present..")
        }
    }
    function checkIfObjectSystemCodeExist(data) {
        if(!data.systemCode) {
            throw new Error("SystemCode is not present..")
        }
    }

    const addObject = ({url, ...data}) => {
        checkIfObjectTypeExist(data)
        return post(url, {
            familyId : state.familyId,
            familyHeadCode : state.familyHeadCode,
            familyHeadType : state.familyHeadType,
            scope : state.familyHeadScope,
            driveCode : driveCode,
            parentObjectCode : state.currentDirectory?.systemCode,
            parentObjectType : state.currentDirectory?.type,
            ...data
        })
    }

    const addMultipartObject = ({url, files, ...data}) => {
        checkIfObjectTypeExist(data)

        return postMultipart(url, {
            familyId : state.familyId,
            familyHeadCode : state.familyHeadCode,
            familyHeadType : state.familyHeadType,
            scope : state.familyHeadScope,
            driveCode : driveCode,
            ...data
        }
        , files)
    }

    const editObjectName = ({url, ...data}) => {
        checkIfObjectTypeExist(data)
        checkIfObjectSystemCodeExist(data)
        return post(url, {
            familyId : state.familyId,
            familyHeadCode : state.familyHeadCode || data.systemCode,
            familyHeadType : state.familyHeadType || data.type,
            scope : state.familyHeadScope,
            driveCode : driveCode,
            ...data
        })
    }

    const editObject = ({url, ...data}) => {
        checkIfObjectTypeExist(data)
        checkIfObjectSystemCodeExist(data)
        return post(url, {
            familyId : state.familyId,
            familyHeadCode : state.familyHeadCode || data.systemCode,
            familyHeadType : state.familyHeadType || data.type,
            scope : state.familyHeadScope,
            driveCode : driveCode,
            ...data
        })
    }

    const getObjectRequestParams = () => {
        return ObjectUtil.deepMerge({}, {
            familyId : state.familyId,
            familyHeadCode : state.familyHeadCode,
            familyHeadType : state.familyHeadType,
            scope : state.familyHeadScope,
            driveCode : driveCode,
        })
    }

    const loadObject = ({url, ...data}) => {
        checkIfObjectTypeExist(data)
        checkIfObjectSystemCodeExist(data)
        return get(url, {
            familyId : state.familyId,
            familyHeadCode : state.familyHeadCode || data.systemCode,
            familyHeadType : state.familyHeadType || data.type,
            scope : state.familyHeadScope,
            driveCode : driveCode,
            ...data
        })
    };

    const loadObjects = ({systemCode, parentObjectCode}) => {
        return get("/file-object/page", {
            systemCode : systemCode || state.familyHeadCode,
            driveCode : driveCode,
            scope : state.familyHeadScope,
            familyHeadType : state.familyHeadType,
            familyHeadCode : state.familyHeadCode,
            parentObjectCode : parentObjectCode,
            type : state.familyHeadType
        })
    }

    const setCurrentDirectory = (directory) => {
        updateFieldValue("currentDirectory", directory);

        if(directory.root) {
            updateFieldValue("familyHeadCode", directory.systemCode)
            updateFieldValue("familyHeadType", directory.type)
        }

        let buffer = [...breadcrumbs]

        // if(index >=0 ) {
        //     buffer = buffer.splice(0, index);
        // }
        // else {
            buffer.push(directory)
        // }
        
        setBreadCrumbs(buffer)    
    }

    const getMandatoryRequestParams = () => {
        return {
            driveCode : driveCode,
            scope : state.familyHeadScope,
            familyHeadType : state.familyHeadType,
            familyHeadCode : state.familyHeadCode,
            parentObjectCode : state.currentDirectory?.systemCode, 
            parentObjectType : state.currentDirectory?.type 
        }
    }

    return (
        <FileSystemContext.Provider value={{ ...state, breadcrumbs,  updateFieldValue, addObject, editObjectName, editObject, loadObject, loadObjects, getObjectRequestParams, addMultipartObject, getMandatoryRequestParams, setCurrentDirectory}}>
          {children}
        </FileSystemContext.Provider>
      );    
};
  


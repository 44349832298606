import { Initials } from "components/object-list/Nuggets/Nugget";
import useAppState from 'hooks/useAppState';
import useHttp from "hooks/useHttp";
import "styles/rootnugget.scss";
import PanelNuggetHelper from 'utils/PanelNuggetHelper/PanelNuggetHelper';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

/**
 * Renders Panel Nugget.
 */
export default function PanelNugget({ keywords }) {
  const { setPanelMenuDisplayed, currentPanel, setCurrentPanel, panels, setUserNavDisplayed } = useAppState();
  const [panelList, setPanelList] = useState([])
  const navigate = useNavigate();
  const {get} = useHttp();

  /**
   * Switches into a specified panel.
   * @param {*} panel  The panel to switch into.
   */
  async function switchPanel(panel) {
    // Create a request to switch panel.
    const res = await get("/identity/switch", { ac: panel.code })
    // Navigate to the specified panel.
    setCurrentPanel(panel);
    setPanelMenuDisplayed(false);
    navigate("/")

    setUserNavDisplayed(false)
  }

  useEffect(() => {
    setPanelList(Object.values(panels));
  }, [panels])

  useEffect(() => {
    setPanelList(PanelNuggetHelper.filterPanels(Object.values(panels), keywords))
  }, [keywords])

  return panelList.map((panel) => {
    const active = currentPanel?.code === panel.code;
    return (
      <div className={active ? "rootnugget panelnugget appactive" : "rootnugget panelnugget"} key={panel.code}>
        <div className="nugget-main">
          <div>
            <div>
              <Initials text={panel.name} id={panel.code} />
            </div>
            <div className="nugget__rootclick-appmain" onClick={() => switchPanel(panel)}>
              <div>
                <span className="nugget-account">{panel.name.toUpperCase()}-{panel.name}</span>
                <span className="nugget-3cc">{panel.shortCode}</span>
              </div>
              {/* <div className="nugget-title">{panel.type.name} PANEL</div> */}
              <div className="nugget-code">{panel.code}</div>
            </div>
          </div>
        </div>
      </div>
    );
  });
}



import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { SideDrawer } from "components/Drawer/Drawer";
import { HorizontalTab, UiTabContext, UiTabPanel } from "components/Tabs";
import { AddButton } from "components/Buttons/ActionButtons";
import Search from "components/object-list/Search/Search";
import ObjectList  from "components/object-list/ObjectList";
import { PageableDataManagerProvider } from "contexts/datamanager/PageableDataManagerContext";
import { DriveDetails } from "./DriveDetail";

const PAGE_URL = "/cloud/drive/page"
export default function DriveList() {
    const { t } = useTranslation();
    const [keywords, setKeywords] = useState(null);
    const [systemCode, setSystemCode] = useState();
    const [details, setShowDetails] = useState();


    function showDetails(systemCode) {
        setSystemCode(systemCode)
        setShowDetails(true)
    }

    const allTabData = {
        'keywords': keywords
    }

    const tabs = [
        <HorizontalTab label="All" value="all" key="all"/>
    ]

    return (
        <>
            <SideDrawer anchor="right" headerTitle={t("drive")} open={details} onClose={() => setShowDetails(false)}>
                <DriveDetails systemCode={systemCode} />
            </SideDrawer>
            <div className="applist applistshow">
                <div className="applistheader">
                    <AddButton onClick={() => showDetails()} />

                    <Search title={t('drives')} setKeywords={setKeywords} />
                </div>
                <div className="applistframe">
                    <UiTabContext tabs={tabs} defaultTab={"all"}>
                        <UiTabPanel value="all" >
                            <PageableDataManagerProvider url={PAGE_URL} eventName={'DRIVE__CREATED'}>
                                <ObjectList data={allTabData}
                                    initials={{text : 'name', id : 'systemCode'}} 
                                    onRowClick={(item) => showDetails(item.systemCode)}
                                    headCells={[
                                        {    
                                            id: 'referenceCode',
                                            numeric: false,
                                            label: 'Reference code',
                                            nuggetInfo : {
                                                order : 3,
                                                type : 'subtitle'
                                            }
                                        },
                                        {    
                                            id: 'name',
                                            numeric: false,
                                            label: 'Name',
                                            nuggetInfo : {
                                                order : 1,
                                                type : 'title'
                                            }
                                        },
                                        {    
                                            id: 'createdAt',
                                            numeric: false,
                                            label: 'Created At',
                                            nuggetInfo : {
                                                order : 4,
                                                type : 'date'
                                            }
                                        },
                                        {    
                                            id: 'state',
                                            numeric: false,
                                            label: 'Status',
                                            nuggetInfo : {
                                                order : 4,
                                                type : 'subtitle'
                                            }
                                        }
                                        ]} />
                            </PageableDataManagerProvider>
                        </UiTabPanel>
                    </UiTabContext>
                </div>
            </div>
        </>
    )
}

import FolderIcon from '@mui/icons-material/Folder';
import { Breadcrumbs, Button, Link, MenuItem, TextField } from "@mui/material";
import { ContextMenuContextProvider } from "components/context-menu/ContextMenuContext";
import DialogBox from 'components/Dialog';
import { FileIcon } from 'components/Icons';
import { DropDownMenu } from "components/Menu/Menu";
import { ClickableNuggetMain, NuggetImageIcon } from 'components/object-list/Nuggets/Nugget';
import Search from "components/object-list/Search/Search";
import { HorizontalTab, UiTabContext, UiTabPanel } from "components/Tabs";
import { PageableDataManagerProvider } from "contexts/datamanager/PageableDataManagerContext";
import { FileSystemProvider } from "contexts/filesystem/FileSystemContext";
import useFileSystem from "hooks/filesystem/useFileSystem";
import { forwardRef, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import StringUtil from 'utils/StringUtil';
import ObjectList from "../../components/object-list/ObjectList";
import { FileObjectContextMenu } from './FileObjectContextMenu';

const PAGE_URL = "/file-object/page"
export const Directory = forwardRef((props, ref) => {
    return <ContextMenuContextProvider>
        <FileSystemProvider>
            <DirectoryList {...props}/>
        </FileSystemProvider>
    </ContextMenuContextProvider>
})

const DirectoryList= forwardRef((props, ref) => {
    const {driveName} = useParams();
    const {t} = useTranslation();
    const [keywords, setKeywords] = useState();
    const {addObject, getMandatoryRequestParams, editObjectName, setCurrentDirectory, breadcrumbs} = useFileSystem();
    const [objects, setObjects] = useState([]);
    const [renameItem, setRenameItem] = useState();
    const [data, setData] = useState();
    const tabs = [
        <HorizontalTab label="All" value="all" key="all"/>
    ]
    
    useEffect(() => {
        setData({keywords : keywords, ...getMandatoryRequestParams()})
    }, [keywords])

    function open(item) {
        setCurrentDirectory(item)
    }
    
    const menuItems = [
        { label: 'Create New Folder', action: () => createNewObject({url : '/file-object/add', name : "New Folder", type : "FOLDER"}) },
        { label: 'Create Block', action: () => createNewObject({url : '/file-object/add', name : "New Block", type : "HTML_BLOCK"}) },
    ];

    
    function createNewObject({url, ...params}) {
        const parentObjectCode =  breadcrumbs[breadcrumbs?.length - 1]?.systemCode
        addObject({url, ...params})
        .then((data) => {
            if(data?.summary) {
                const buffer = [...objects]
                const {summary} = data;
                summary.parentObjectCode = parentObjectCode
                buffer.push(summary)
    
                setObjects(buffer)        
            }
        }) 
    }

    function actionMenu(item) {
        return <FileObjectContextMenu item={item} onRename={() => setRenameItem(item)}/>
    }
    
    function handleRename() {
        editObjectName({url : '/file-object/basic',  name : renameItem.name, systemCode : renameItem.systemCode, type : renameItem.type}).then(() => setRenameItem(null));
    }

    function nuggetTemplate(item) {
        return <div className={item.focused ? "rootnugget appactive" : "rootnugget"} >
                <ClickableNuggetMain item={item} onClick={() => open(item)}>
                    <div>
                        <NuggetImageIcon icon={item.directory ? <FolderIcon /> : <FileIcon fileType={item.type} />}/>
                        <div className="nugget__rootclick-appmain">
                            <div className={"nugget-title"}>
                                {item.name}
                            </div>
                            <div className={"nugget-subtitle"}>
                                {item.handle}
                            </div>
                        </div>
                    </div>
                </ClickableNuggetMain>
                <div className="nugget-after">
                    {actionMenu(item)}
                </div>
            </div>
    }

    return data && <div>
                {renameItem && <DialogBox 
                    open={true} 
                    title={"Rename :: " + renameItem.handle} 
                    description={<TextField label={t("name")} value={renameItem.name} size="small" onChange={(e) => setRenameItem((prevState) => { return {...prevState, name : e.target.value}})}/>} 
                    handleClose={() => setRenameItem(null)} buttons={[
                        <Button key={"Yes"} onClick={() => handleRename()}>{t("update")}</Button>,
                        <Button key={"No"} onClick={() => setRenameItem(null)}>{t("cancel")}</Button>
                    ]
                } />}
                <div className="applist applistshow">
                    <div className="applistheader">
                        <DropDownMenu>
                            {menuItems.map((item, index) => <MenuItem key={index} onClick={item.action}>{item.label}</MenuItem>)}
                        </DropDownMenu>                                    
                        <Search title={driveName} setKeywords={setKeywords} />
                    </div>
                    <div className="applistframe">
                        <UiTabContext tabs={tabs} defaultTab={"all"}>
                            <UiTabPanel value="all" >
                                <Breadcrumbs maxItems={2} aria-label="breadcrumb" sx={{marginY : '5px'}}>
                                    {breadcrumbs.map((b, index) => {
                                        return <Link underline="hover" color="inherit" href="#" onClick={() => {}} key={b.systemCode}>
                                            {b.name}
                                        </Link>
                                    })}
                                </Breadcrumbs>
                                <PageableDataManagerProvider url={PAGE_URL} eventName={'DRIVE__CREATED'}>
                                    <ObjectList data={data}
                                        onRowClick={(item) => open(item)}
                                        nuggetTemplate={nuggetTemplate}
                                        actionMenu={actionMenu}
                                        headCells={[
                                            {    
                                                id: 'systemCode',
                                                numeric: false,
                                                label: '',
                                                formatter : (item) => item.directory ? <FolderIcon /> : <FileIcon fileType={item.type}/>,
                                                nuggetInfo : {
                                                    order : 6,
                                                    type : 'subtitle'
                                                }
                                            }, 
                                            {    
                                                id: 'handle',
                                                numeric: false,
                                                label: 'Handle',
                                                nuggetInfo : {
                                                    order : 3,
                                                    type : 'subtitle'
                                                }
                                            },
                                            {    
                                                id: 'name',
                                                numeric: false,
                                                label: 'Name',
                                                nuggetInfo : {
                                                    order : 1,
                                                    type : 'title'
                                                }
                                            },
                                            {    
                                                id: 'type',
                                                numeric: false,
                                                label: 'Type',
                                                nuggetInfo : {
                                                    order : 1,
                                                    type : 'subtitle'
                                                },
                                                formatter : (item) => StringUtil.getLabel(item.type),
                                            },
                                            {    
                                                id: 'createdAt',
                                                numeric: false,
                                                label: 'Created At',
                                                nuggetInfo : {
                                                    order : 4,
                                                    type : 'date'
                                                }
                                            }
                                            ]} />
                                </PageableDataManagerProvider>
                            </UiTabPanel>
                        </UiTabContext>
                    </div>
                </div>
            </div>
});

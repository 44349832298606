import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { tr } from 'date-fns/locale';
import { useState } from 'react';

export function StandardAccordian(props) {
  const { title, open = false, children } = props;
  // Initialize the state to true to keep the Accordion open initially
  const [expanded, setExpanded] = useState(open ? true : false);

  const handleChange = (event, newExpanded) => {
    setExpanded(newExpanded);
  };
  return (
    <Box>
      <Accordion
        disableGutters
        elevation={0}
        expanded={expanded}
        onChange={handleChange}
        sx={{
          backgroundColor: 'transparent',
          borderTop: '1px solid rgba(0, 0, 0, 0.1)', // Lighter top border
          borderBottom: '1px solid rgba(0, 0, 0, 0.1)', // Lighter bottom border
          '&:before': { display: 'none' }, // Remove default MUI border
          borderLeft: 'none', // Remove left border
          borderRight: 'none', // Remove right border
          padding: 0, // Remove padding inside accordion
        }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='features-content' id='features-header'>
          <Typography variant='h6'>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </Box>
  );
}

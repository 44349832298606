import { LoadingButton } from "@mui/lab";
import { Box, Card } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FormBox } from "components/Box/Box";
import { HotGrid180 } from "components/Containers/Grid";
import { FormNumericInput } from "components/Form/FormInputs";
import { HorizontalTab, UiTabContext, UiTabPanel } from "components/Tabs";
import dayjs from 'dayjs';
import { Formik } from "formik";
import useHttp from "hooks/useHttp";
import { useEffect, useState } from "react";
import * as Yup from "yup";

// form field validation schema
const validationSchema = Yup.object().shape({
    startDate: Yup.date().required("Start Date is required!"),
    endDate: Yup.date().required("End Date is required!"),
  });
  
const DETAIL_URL = "/cloud/subscription/show"
const SAVE_URL = "/cloud/subscription/save"
export function SubscriptionDetail({accountCode, id}) {
    const [subscriptionDetail, setSubscriptionDetail] = useState({
        startDate : new Date(),
        endDate : new Date(),
        employees : 0
    });

    const [loading, setLoading] = useState(false)
    const {get, post} = useHttp();
    useEffect(() => {
        if(id) {
            get(DETAIL_URL, {ac : accountCode, sb : id}).then(res => setSubscriptionDetail(res))
        }
    }, [accountCode, id])

    const tabs = [
        <HorizontalTab label="Main" value="main" key="main"/>,
        <HorizontalTab label="Audit Logs" value="logs" key="logs"/>,
    ]

    const handleFormSubmit = async (values) => {
        const data = {...values}
        data.accountCode = accountCode
        data['allowances'] = {EMPLOYEE : values.employees};
        setLoading(true);
        post(SAVE_URL, data).then(res => setLoading(false));
    };
  

    return subscriptionDetail && (
        <Box>
            <UiTabContext tabs={tabs} defaultTab={"main"}>
                <UiTabPanel value="main" >
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={subscriptionDetail}
                    validationSchema={validationSchema}>
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                        <FormBox>
                            <form onSubmit={handleSubmit}>
                                <SubscriptionForm handleBlur={handleBlur} handleChange={handleChange} values={values} touched={touched} errors={errors}/>
                                <LoadingButton
                                    type="submit"
                                    color="primary"
                                    loading={loading}
                                    variant="contained"
                                    sx={{ my: 2 }}>
                                    Save
                                </LoadingButton>    
                            </form>
                        </FormBox>
                    )}
                    </Formik>
                </UiTabPanel>
                <UiTabPanel value="logs" >
                </UiTabPanel>
            </UiTabContext>      
        </Box>
      );
}

export function SubscriptionForm({handleBlur, handleChange, values, touched, errors}) {
    return <Card className="card" sx={{paddingX : 2}}>
                <HotGrid180>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            size="small"
                            name="startDate"
                            label="Start Date"
                            variant="outlined"
                            onBlur={handleBlur}
                            value={dayjs(values.startDate)}
                            onChange={handleChange}
                            helperText={touched.startDate && errors.startDate}
                            error={Boolean(errors.startDate && touched.startDate)}
                            />
                        <DatePicker
                            size="small"
                            name="endDate"
                            label="End Date"
                            variant="outlined"
                            onBlur={handleBlur}
                            value={dayjs(values.endDate)}
                            onChange={handleChange}
                            helperText={touched.endDate && errors.endDate}
                            error={Boolean(errors.endDate && touched.endDate)}
                            />
                    </LocalizationProvider>
                    <FormNumericInput
                        label="Users"
                        name="employees"
                        onBlur={handleBlur}
                        value={values.employees}
                        onChange={handleChange}                                        
                    />
                </HotGrid180>
                            
            </Card>
}
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SideDrawer } from 'components/Drawer/Drawer';
import { HorizontalTab, UiTabContext, UiTabPanel } from 'components/Tabs';
import { AddButton, UploadButton } from 'components/Buttons/ActionButtons';
import RequestPath from 'common/RequestPath';
import Search from 'components/object-list/Search/Search';
import ObjectList from 'components/object-list/ObjectList';
import { PageableDataManagerProvider } from 'contexts/datamanager/PageableDataManagerContext';
import { EmployeeDetail } from './EmployeeDetail';

export default function EmployeeList() {
  const { t } = useTranslation();
  const [keywords, setKeywords] = useState(null);
  const [systemCode, setSystemCode] = useState();
  const [details, setShowDetails] = useState();
  const PAGE_URL = '/cloud/people/page';
  const [userTabData] = useState({ keywords: keywords });

  function showDetails(systemCode) {
    setSystemCode(systemCode);
    setShowDetails(true);
  }

  const tabs = [<HorizontalTab label='Users' value='users' key='users' />, <HorizontalTab label='Sales' value='sales' key='sales' />];

  return (
    <>
      <SideDrawer anchor='right' headerTitle={t('user')} open={details} onClose={() => setShowDetails(false)}>
        <EmployeeDetail systemCode={systemCode} />
      </SideDrawer>
      <div className='applist applistshow'>
        <div className='applistheader'>
          <AddButton onClick={() => showDetails()} />

          <UploadButton url={RequestPath.TEAM_UPLOAD} />
          <Search title={t('Users')} setKeywords={setKeywords} />
        </div>
        <div className='applistframe'>
          <UiTabContext tabs={tabs} defaultTab={'users'}>
            <UiTabPanel value='users'>
              <PageableDataManagerProvider url={PAGE_URL} eventName={'EMPLOYEE__CREATED'}>
                <ObjectList
                  data={userTabData}
                  initials={{ text: 'name', id: 'systemCode' }}
                  onRowClick={item => showDetails(item.systemCode)}
                  headCells={[
                    {
                      id: 'referenceCode',
                      numeric: false,
                      label: 'Reference code',
                      nuggetInfo: {
                        order: 3,
                        type: 'subtitle',
                      },
                    },
                    {
                      id: 'name',
                      numeric: false,
                      label: 'Name',
                      nuggetInfo: {
                        order: 1,
                        type: 'title',
                      },
                    },
                    {
                      id: 'title',
                      numeric: false,
                      label: 'Title',
                      nuggetInfo: {
                        order: 2,
                        type: 'subtitle',
                      },
                    },
                    {
                      id: 'state',
                      numeric: false,
                      label: 'Status',
                      nuggetInfo: {
                        order: 4,
                        type: 'subtitle',
                      },
                    },
                  ]}
                />
              </PageableDataManagerProvider>
            </UiTabPanel>
            <UiTabPanel value='sales'>{/* <PageableOnScrollList dataManager={dataManager} data={salesTabData} nuggetTemplate={nuggetTemplate} /> */}</UiTabPanel>
          </UiTabContext>
        </div>
      </div>
    </>
  );
}

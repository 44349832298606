
import { FileUtil } from 'utils/FileUtil';

export default class NavService {

  static async getNavItems(panelType) {
    console.log("Getting nav items for panel type:", panelType);
    if (panelType.items) {
      return panelType.items;
    }

    const data = FileUtil.readJsonFile(`/assets/nav/${panelType.fileName.toLowerCase()}.json`);
    return data.items || [];
  }
}

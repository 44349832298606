import { Button, ButtonGroup, ClickAwayListener, Grow, ListItem, MenuItem, MenuList, Paper, Popper, Stack } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import StringUtil from "utils/StringUtil";
import { useTranslation } from "react-i18next";
import CopyToClipboard from "components/CopyToClipboard";
import "./buttons.scss";
import useHttp from "hooks/useHttp";
import { useEffect, useRef, useState } from "react";

class AppStateHolder{
    static appState = {
        setAppError: (message) => {},
        setShowSuccessMessage: (message) => {},
    };
}

export function ResetButton(props) {
    const {t} = useTranslation();

    const {label, ...others} = props;
    const resetLabel = StringUtil.isNotBlank(label) ? label: t('reset')
    return <ActionButton label={resetLabel} {...others}/>     
}

export function ActionButton(props) {
    const {label, ...others} = props;
    return <Button variant="contained" {...others}> {label} </Button>        
}


export function AddMoreActionButton(props) {
    const {t} = useTranslation();
    return <Button variant="text" {...props}>
        {t('generic-label-add-more')}
    </Button>        
}

export function SaveActionButton(props) {
    const {label, ...others} = props;
    const {t} = useTranslation();
    const saveLabel = <><i className="fa fa-save"></i>&nbsp; {StringUtil.isNotBlank(label) ? label: t('generic-label-save')}</>
    return <ActionButton label={saveLabel} {...others}/>
}

export function AddButton(props) {
    const {title} = props;
    const url = props.url;
    const {t} = useTranslation();
    const appState = AppStateHolder.appState;
    
    function onClick() {
        if(url) {
            appState.setObjectDetailUrl(url);
        }
        else if(props.onClick && typeof(props.onClick) === 'function') {
            props.onClick()
        }
    }

    return  <div className="applistmenuclick app-main-link" title={StringUtil.nullSafe(title, 'new')} onClick={onClick}>
                <i className="fas fa-plus"> &nbsp;</i>
            </div>
}

export function CopyCountryHeaders(props) {
    const {prefix} = props;
    const [headers, setHeaders] = useState([]);
    const [countries, setCountries] = useState([]);
    const options = ['Copy country headers'] 
    const [displayHeaders, setDisplayHeaders] = useState(false);
    const {get} = useHttp();

    useEffect(() => {
        get("/location/countries").then(res => {
            const buffer = res.countries.map(c => prefix ? prefix + c.isoAlpha2Code : c.isoAlpha2Code);
            
            setHeaders([buffer.join("\t")]);
            setCountries(res.countries);
        });
    }, [prefix])

    return <div><ComboButtonsToCopyHeaders options={options} headers={headers} />            
            &nbsp;
            {displayHeaders 
                    ? <span className="curp inline-link" onClick={() => setDisplayHeaders(false)}>Hide all</span>
                    : <span className="curp inline-link" onClick={() => setDisplayHeaders(true)}>Show all</span>}
                {displayHeaders && <Stack spacing={-2}>
                     {countries && countries.map(country => <ListItem key={country.isoAlpha2Code}>{country.name} ({prefix ? prefix + country.isoAlpha2Code : country.isoAlpha2Code})</ListItem>)}
                 </Stack>
                }
            </div>
}


export function CopyMandatoryAndOptionalHeadersButton(props) {
    const {mandatoryHeaders, optionalHeaders} = props;
    const options = ['Copy mandatory headers', 'Copy all headers'] 
    const [headers, setHeaders] = useState([])
    const [displayHeaders, setDisplayHeaders] = useState(false);
    
    useEffect(() => {
        setHeaders([mandatoryHeaders.join("\t"), mandatoryHeaders.concat(optionalHeaders).join("\t")]);
    }, [mandatoryHeaders, optionalHeaders])
    
    return <div>
            <ComboButtonsToCopyHeaders options={options} headers={headers} />            
            &nbsp;
            {displayHeaders 
                    ? <span className="curp inline-link" onClick={() => setDisplayHeaders(false)}>Hide all</span>
                    : <span className="curp inline-link" onClick={() => setDisplayHeaders(true)}>Show all</span>}
                {displayHeaders && <><Stack spacing={-2}>
                     {mandatoryHeaders && mandatoryHeaders.map(header => <ListItem className="mandatory" key={header}>{header}</ListItem>)}
                     {optionalHeaders && optionalHeaders.map(header => <ListItem className="optional" key={header}>{header}</ListItem>)}
                 </Stack></>
                }
            </div>
}

export function DashboardButton(props) {
    const url = props.url;
    const {t} = useTranslation();
    const appState = AppStateHolder.appState;

    return  <div className="applistmenuclick" title={t("dashboard")} onClick={() => appState.setObjectDetailUrl(url)}>
                <i className="fas fa-chart-bar"> &nbsp;</i>
            </div>
}


export function SettingButton(props) {
    const url = props.url;
    const { t } = useTranslation();
    const appState = AppStateHolder.appState;

    function onClick() {
        if (url) {
            appState.setObjectDetailUrl(url);
        }
        else if (props.onClick && typeof (props.onClick) === 'function') {
            props.onClick()
        }
    }

    return <div className="applistmenuclick app-main-link" title={t('settings')} onClick={onClick}>
        <i className="fas fa-cog"> &nbsp;</i>
    </div>
}

/**
 * An upload button to display on applist.
 */
export function UploadButton(props) {
    const url = props.url;
    const {t} = useTranslation();
    const appState = AppStateHolder.appState;

    
    function onClick() {
        if(url) {
            appState.setObjectDetailUrl(url);
        }
        else if(props.onClick && typeof(props.onClick) === 'function') {
            props.onClick()
        }
    }

    return  <div className="applistmenuclick" title={t("upload")} onClick={onClick}>
                <i className="fas fa-upload"> &nbsp;</i>
            </div>
}

export function ComboButtonsToCopyHeaders(props) {
    const {options, headers} = props;

    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const anchorRef = useRef(null);
    
    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
    };
    
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    
    return <ButtonGroup variant="outlined" aria-label="split button" ref={anchorRef}>
                <CopyToClipboard value={headers[selectedIndex]}>
                    <Button 
                        sx={{maxWidth : "150px", 
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            display: "inline-block"
                        }}
                    >{options[selectedIndex]}</Button>
                </CopyToClipboard>
                <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    
                >
                    <ArrowDropDownIcon />
                </Button>
                <Popper
                    sx={{
                    zIndex: 1,
                    }}
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                        transformOrigin:
                            placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                {options.map((option, index) => (
                                    <CopyToClipboard value={headers[index]} key={option}>
                                        <MenuItem selected={index === selectedIndex} onClick={(event) => handleMenuItemClick(event, index)}>
                                            {option}
                                        </MenuItem>
                                    </CopyToClipboard>
                                ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
                </Popper>
            </ButtonGroup>   
}
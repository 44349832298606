import { CssBaseline, ThemeProvider } from "@mui/material";
import { createContext, useContext, useEffect, useState } from "react";
import comfortableTheme from "./comfortable-theme";
import compactTheme from "./compact-theme";

const ThemeContext = createContext({
  changeTheme: () => {},
});

export const ThemeContextProvider = ({ children }) => {
    const [currentTheme, setCurrentTheme] = useState(compactTheme);
    const [themeName, setThemeName] = useState('compact');
    const themes = {
        comfort : comfortableTheme,
        compact : compactTheme
    }  
    const changeTheme = (theme) => {
      setCurrentTheme(themes[theme])
      setThemeName(theme)
    };
  
    return (
      <ThemeContext.Provider value={{ theme : themeName, changeTheme }}>
        <ThemeProvider theme={currentTheme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </ThemeContext.Provider>
    );
};

export default function useTheme() {
  return useContext(ThemeContext);
}
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import {
    Avatar,
    Box,
    IconButton,
    TextField,
    Toolbar
} from '@mui/material';
import { UserNavigationBackDrop, UserNavigationConsole } from 'components/UserNavigation/UserNavigation';
import useAppState from 'hooks/useAppState';
import { headerHeight } from './constants';
import { HeaderContainer } from './HeaderContainer';
export default function Header() {
    const {currentPanel, setAppNavDisplayed, appNavDisplayed, userNavDisplayed, setUserNavDisplayed} = useAppState();
    
    const toggleLeftSidebar = () => {
        setAppNavDisplayed(!appNavDisplayed);
    };

    /* Header */
    return<>
            <HeaderContainer>
                <Toolbar sx={{ minHeight: headerHeight + " !important" }}>
                    <IconButton color="inherit" edge="start" onClick={toggleLeftSidebar} sx={{ mr: 2,  }}>
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{
                        width: {xs : '100px', sm : 'auto'}
                        , maxWidth: {xs : '100px', sm : '200px'}
                        , mr : 2
                        , textOverflow : 'ellipsis'
                        , whiteSpace : 'nowrap'
                        , overflow : 'hidden'}}>
                            {currentPanel.name}
                    </Box>
                    <TextField
                        variant="outlined"
                        placeholder="Search"
                        size="small"
                        sx={{ bgcolor: 'white', borderRadius: 1, mr: 2, marginY: 2, flexGrow: 0.5 }}
                        InputProps={{
                        startAdornment: <SearchIcon color="action" sx={{ mr: 1 }} />,
                        }}
                    />
                    <Box
                        sx={{flexGrow: 0.5 }}
                    ></Box>
                    <IconButton color="inherit" onClick={() => setUserNavDisplayed(!userNavDisplayed)}>
                        <Avatar src="/broken-image.jpg" />
                    </IconButton>
                </Toolbar>
            </HeaderContainer>
            <div className={userNavDisplayed ? "usernavmenu usernavmenushow" : "usernavmenu usernavmenuhide"}>
                <UserNavigationBackDrop />
                <UserNavigationConsole />
            </div>
    </>
}
import { createTheme } from '@mui/material/styles';
import styleVariables from 'styles/appvariables.scss';

const baseTheme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Primary color
    },
    secondary: {
      main: '#dc004e', // Secondary color
    },
  },
  typography: {
    fontFamily: "'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
  },
  components: {
    // Tabs globally
    MuiTabs: {
      styleOverrides: {
        root: {
          p: 1,
          minHeight: styleVariables.muiTabHeight,
        },
        flexContainer: {
          minHeight: styleVariables.muiTabHeight,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: '35px',
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: '0',
        },
      },
    },
  },
});

export default baseTheme;

import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import { Properties } from '../../components/properties/properties';
import { Typography } from '@mui/material';
import { useEffect } from 'react';
import useHttp from 'hooks/useHttp';

const membersMenu = [
  {
    name : 'Company',
    section : true,
  }, 
  {
    name : 'Structure',
    icon : <PeopleAltIcon />,
    nextLevel : [
        {
            name : 'Group of Companies',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, 
        {
            name : 'Units and Functions',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, 
        {
            name : 'Org',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }
    ],
  }, 
  { name : 'Setup',
    icon : <PeopleAltIcon />,
    nextLevel : [
        {
            name : 'About',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Addresses',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Locations',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Payments',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Legal',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Contact Us',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Messaging',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }
    ],
  }, 
  { name : 'Record-keeping',
    icon : <PeopleAltIcon />,
    nextLevel : [
        {
            name : 'Transaction Type',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Log Type',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Request Type',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Planning Type',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Dataset Type',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Handshake Type',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }
    ],
  }, 
  { name : 'Bookkeeping',
    icon : <PeopleAltIcon />,
    nextLevel : [
        {
            name : 'Setup',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Currency',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Currency Conversions',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Timeline Cutoffs',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Default Accounting Settings',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Chart of Accounts',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Predefined Ledgers',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, {
            name : 'Fixed Asset',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }
    ],
  }, 
  { name : 'Planning',
    icon : <PeopleAltIcon />,
    nextLevel : [
        {
            name : 'Setup',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }
        , {
            name : 'Timeline Cutoffs',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }
    ],
  }, 
  { name : 'Networks',
    icon : <PeopleAltIcon />,
    nextLevel : [
        {
            name : 'Planning Type',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }
    ],
  },
  {
    name : 'Group',
    section : true,
  }, 
  {
    name : 'Hierarchy',
    icon : <PeopleAltIcon />,
    nextLevel : [
        {
            name : 'Group of Companies',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, 
        {
            name : 'Units and Functions',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, 
        {
            name : 'Org',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }
    ],
  }, 
  {
    name : 'Consolidation',
    icon : <PeopleAltIcon />,
    nextLevel : [
        {
            name : 'Group of Companies',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, 
        {
            name : 'Units and Functions',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }, 
        {
            name : 'Org',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }
    ],
  }, 
  {
    name : 'More',
    section : true,
  }, 
  { name : 'Vault',
    icon : <PeopleAltIcon />,
    nextLevel : [
        {
            name : 'Inbound Data Rules',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        },
        {
            name : 'Sub-Drive',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        },
        {
            name : 'Binders',
            icon : <StarBorderOutlinedIcon />,
            component : <></>
        }
    ],
  }, 
  { name : 'Admin Center',
    icon : <PeopleAltIcon />,
    component : <><Typography>Open the Properties Window</Typography></>
  }, 
]
  
export function Bizmo() {
    return <Properties menu={membersMenu} title={"Bizmo"}/>
}
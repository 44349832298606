import { createTheme } from '@mui/material/styles';
import baseTheme from './base-theme';

const compactTheme = createTheme({
  ...baseTheme,
  typography: {
    ...baseTheme.typography, // Retain all base typography styles
    fontSize: '0.9rem', // Set the root font size
  },
  components: {
    ...baseTheme.components,
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: '5px !important',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          paddingTop: '3px',
          paddingBottom: '3px',
          '& .MuiButtonBase-root': {
            paddingTop: '3px',
            paddingBottom: '3px',
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '30px',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          height: '24px',
          width: '24px',
          fontSize: '0.75rem',
        },
      },
    },
  },
});

export default compactTheme;

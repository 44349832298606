import { HotGrid280 } from 'components/Containers/Grid';
import usePageableDataManager from 'hooks/datamanager/usePageableDataManager';
import { useEffect, useState } from 'react';

export default function PagebaleCardGrid(props) {
  const { data, getCard } = props;
  const { items, loadPage, totalCount } = usePageableDataManager();
  const [page, setPage] = useState();
  const [pageSize, setPageSize] = useState();

  useEffect(() => {
    loadPage({ ...data, pageSize: pageSize, rows: pageSize }, 0);
  }, [data]);

  return (
    <HotGrid280 sx={{ height: '100vh', overflow: 'auto' }}>
      {items.map((item, index) => {
        return getCard(item, index);
      })}
    </HotGrid280>
  );
}

import { SmartLabel } from "components/Box/Box";
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { CollectionUtil } from "utils/CollectionUtil";
import * as React from 'react';
import { useState } from "react";

export function AppMainMenuTree(props) {
    const {menu, menuAnchor, ...others} = props;
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [currentMenuItems, setCurrentMenuItems] = useState(menu && menu.menuItems ? menu.menuItems : []);

    const handleToggle = () => {
        setOpen(!open);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
        } else if (event.key === 'Escape') {
        setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    function handleMenuAnchorClick(e) {
        handleToggle();
        setCurrentMenuItems(menu && menu.menuItems ? menu.menuItems : []);

        if(menuAnchor) {
            const onClick = menuAnchor.props.onClick;
            if(onClick && typeof(onClick) === 'function') {
                onClick(e);
            }
        }
    }

    function handleMenuClick(menuItem) {
        if(menuItem) {
            if(CollectionUtil.isNotBlank(menuItem.children)) {
                menuItem.children.forEach(childMenuItem => childMenuItem.parent = menuItem);
                setCurrentMenuItems(menuItem.children)
            }
            
            if(menuItem.onClick && typeof menuItem.onClick === 'function' ) {
                menuItem.onClick(menuItem);
            }
        }
    }

    function handleBackButtonClick(parentMenuItem) {
        if(parentMenuItem && parentMenuItem != null) {
            if(parentMenuItem.parent) {
                setCurrentMenuItems(parentMenuItem.parent.children);
            } else {
                setCurrentMenuItems(menu.menuItems);
            }
        } else {
            setOpen(false);
        }
    }

    function closeMenu() {
        setOpen(false)
    }

    const menuTrigger = menuAnchor ? React.cloneElement(menuAnchor, {
        onClick : handleMenuAnchorClick,
        ref: anchorRef
    })
    : <Button  size="small" ref={anchorRef} onClick={handleMenuAnchorClick}> <MenuIcon /> </Button>;

    return <>
        {menuTrigger}
        <Popper sx={{zIndex: 10,}} open={open} anchorEl={anchorRef.current} disablePortal placement="bottom-start">
            <ClickAwayListener onClickAway={handleClose}>
                <Paper sx={{width:"200px"}} elevation={3}>
                    <>
                        {
                            CollectionUtil.isNotBlank(currentMenuItems) && <MenuList id="composition-menu" onKeyDown={handleListKeyDown} >
                                <MenuItem>
                                    <div style={{width:"100%", display:"flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                                        <div onClick={(e) => handleBackButtonClick(currentMenuItems[0].parent)}><span><i className="fas fa-arrow-left">&nbsp;</i></span></div>
                                        <div onClick={(e) => closeMenu()}><i className="fas fa-times">&nbsp;</i></div>
                                    </div>
                                </MenuItem>
                            {
                                currentMenuItems.map(menuItem => {
                                        return <MenuItem onClick={(e) => handleMenuClick(menuItem)} key={menuItem.label}>
                                            <div style={{width:"100%", display:"flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                                                <div><SmartLabel>{menuItem.label}</SmartLabel> </div>
                                                <div>{CollectionUtil.isNotBlank(menuItem.children) && <i className="fas fa-angle-right">&nbsp;</i>}</div>
                                            </div>
                                        </MenuItem>
                                    }
                                )
                            }
                            </MenuList>
                        }
                    </>
                </Paper>
            </ClickAwayListener>
        </Popper>
    </>
}

export function AppFlatMenuList(props) {
    const {menuAnchor, ...others} = props;
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen(!open);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
        } else if (event.key === 'Escape') {
        setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    function handleMenuAnchorClick(e) {
        handleToggle();

        if(menuAnchor) {
            const onClick = menuAnchor.props.onClick;
            if(onClick && typeof(onClick) === 'function') {
                onClick(e);
            }
        }
    }

    function closeMenu() {
        setOpen(false)
    }

    const menuTrigger = menuAnchor ? React.cloneElement(menuAnchor, {
        onClick : handleMenuAnchorClick,
        ref: anchorRef
    })
    : <Button  size="small" ref={anchorRef} onClick={handleMenuAnchorClick}> <MenuIcon /> </Button>;

    return <>
        {menuTrigger}
        <Popper sx={{zIndex: 10,}} open={open} anchorEl={anchorRef.current} disablePortal placement="bottom-start">
            <ClickAwayListener onClickAway={handleClose}>
                <Paper sx={{width:"200px"}} elevation={3}>
                        <>
                            {
                                props.children && <MenuList id="composition-menu" onKeyDown={handleListKeyDown} >
                                    {props.children}
                                </MenuList>
                            }
                        </>
                </Paper>
            </ClickAwayListener>
        </Popper>
    </>
}
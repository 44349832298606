import { CssBaseline } from '@mui/material';
import { AppStateProvider } from 'contexts/AppStateContext';
import { ErrorContextProvider } from 'contexts/ErrorSuccessContext';
import { HttpContextProvider } from 'contexts/HttpContext';
import { AuthProvider } from 'contexts/JWTAuthContext';
import { useRoutes } from 'react-router-dom';
import routes from 'routes/Routes';
import './App.css';
import './styles/app-root.scss';
import './assets/c/common/font-awesome.css';
import './i18n/config';

/**
 * Hotlink application.
 */
export default function AppContainer() {
  const content = useRoutes(routes);

  return (
    <ErrorContextProvider>
      <HttpContextProvider>
        <AuthProvider>
          <AppStateProvider>
            <CssBaseline />
            {content}
          </AppStateProvider>
        </AuthProvider>
      </HttpContextProvider>
    </ErrorContextProvider>
  );
}

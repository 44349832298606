import { Box, TextField, Typography } from "@mui/material";
import { firstColumnRightBorder, firstColumnWidth, mainTitleColor, mainTitleFontSize, menuBackgroundColor, menuPadding } from "./constants";

export function PropertiesSecondColumn({title, ...props}) {
    return <Box
                sx={{
                backgroundColor: menuBackgroundColor,
                borderRight: firstColumnRightBorder,
                paddingTop : menuPadding,
                width : firstColumnWidth
            }}
            >
                <Box sx={{paddingX : menuPadding}}>
                    <Typography
                        variant="h6"
                        sx={{ mb: menuPadding, fontSize : '1rem', fontWeight: 600, color: "#555" }}
                    >
                        {title}
                    </Typography>
                </Box>
                {props.children}
            </Box>

}
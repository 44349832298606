import { ObjectUtil } from 'utils/ObjectUtil';

/**
 * Utility class to manage collections
 */
export class CollectionUtil {

    /**
     * It removes the first match element from array.
     * @param inputArr Input array from which elment has to be removed based on matching condition
     * @param matchFunc Matching function to identify element that has to be removed from array
     */
    static removeFirstMatchFromArray(inputArr, matchFunc) {
        if(!matchFunc) {
            console.log("Matching function must be defined to select element to be removed from array");
            return;
        }
        if(inputArr && inputArr.length > 0 && matchFunc) {
            var i = inputArr.length;
            while (i--) {
                if (matchFunc(inputArr[i], i)) {
                    inputArr.splice(i, 1);
                    return;
                }
            }
        } else {
            console.log("Valid array input is required to filter any element.")
        }
    }
    

    /**
     * It removes the all matched elements from array.
     * @param inputArr Input array from which elments has to be removed based on matching condition
     * @param matchFunc Matching function to identify element that has to be removed from array
     */
    static removeAllMatchFromArray(inputArr, matchFunc) {
        if(!matchFunc) {
            console.log("Matching function must be defined to select element to be removed from array");
            return;
        }
        if(Array.isArray(inputArr) && matchFunc) {
            return inputArr.filter((item) => !matchFunc(item));
        } else {
            console.log("Valid array input is required to filter any element.")
        }
    }

    /**
     * It checks if input arrray has matching element
     * @param {*} inputArr Input array in which element has to be searched
     * @param {*} matchFunc Matching function by which matching element will be selected
     * @returns true only if any matching element is found
     */
    static hasMatchingElement(inputArr, matchFunc) {
        if(!matchFunc) {
            console.log("Matching function must be defined to select element to be removed from array");
            return;
        }
        if(inputArr && matchFunc) {
           return inputArr.findIndex(matchFunc) !== -1;
        } else {
            console.log("Valid array input is required to filter any element.")
        }
        return false;
    }

    static isNotBlank(inputArr) {
        return inputArr && inputArr.length > 0;
    }

    static allNotBlank(...inputArgs) {
        const firstBlankInput = CollectionUtil.findFrstMatch(inputArgs, arr => arr && CollectionUtil.isBlank(arr));
        if(firstBlankInput && firstBlankInput != null) {
            return false;
        }
        return true;
    }

    static isBlank(inputArr) {
        return !inputArr || inputArr.length === 0;
    }

    static nullSafe(inputArr) {
        return CollectionUtil.isBlank(inputArr) ? [] : inputArr;
    }

    static findFrstMatch(inputArr, matchFunc) {
        if(!matchFunc) {
            console.log("Matching function must be defined to select element to be removed from array");
        } else if (inputArr && inputArr.length > 0 && matchFunc) {
           const matchedIndex =  inputArr.findIndex(matchFunc);
           if(matchedIndex !== -1) {
               return inputArr[matchedIndex];
           }
        } else {
            console.log("Valid array input is required to filter any element.")
        }
        return null;
    }

    static deepMergeFirstMatch(inputArr, objectToMerge, matchFunc) {
        if(!matchFunc) {
            console.log("Matching function must be defined to select element to be removed from array");
        } else if (inputArr && inputArr.length > 0 && matchFunc) {
            return inputArr.map(item => matchFunc(item) ? ObjectUtil.deepMerge(item, objectToMerge) : item);
        } else {
            console.log("Valid array input is required to filter any element.")
        }
        return null;
    }

    static sort(array, property) {
        if(array) {

            if(property) {
                array.sort(CollectionUtil.dynamicSort(property))
            }
            else {
                array.sort();
            }
        }
    }

    static dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
            * and you may want to customize it to your needs
            */
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    static groupBy(array, key) {
        return array.reduce(function (r, a) {
            r[a[key]] = r[a[key]] || [];
            r[a[key]].push(a);
            return r;
        }, Object.create(null));
    }

    static updateArrayValueAtField(obj, fieldName, arrayIndex, newValue) {
        if (typeof obj !== 'object' || obj === null) {
          throw new Error('Invalid input: The first parameter should be a valid object.');
        }
      
        // Check if the field exists and is an array
        if (obj.hasOwnProperty(fieldName) && Array.isArray(obj[fieldName])) {
          // Create a copy of the array to maintain immutability
          const updatedArray = [...obj[fieldName]];
      
          // Replace the value at the specified index
          updatedArray[arrayIndex] = newValue;
      
          // Create a new object with the updated field value
          return { ...obj, [fieldName]: updatedArray };
        }
      
        // If the field doesn't exist or is not an array, return the original object
        return obj;
    }

    static addToArrayAtField(obj, fieldName, newValue) {
        if (typeof obj !== 'object' || obj === null) {
          throw new Error('Invalid input: The first parameter should be a valid object.');
        }
      
        // Check if the field exists and is an array
        if (obj.hasOwnProperty(fieldName) && Array.isArray(obj[fieldName])) {
          // Create a copy of the array to maintain immutability
          const updatedArray = [...obj[fieldName]];
      
          // Add the new value to the end of the array
          updatedArray.push(newValue);
      
          // Create a new object with the updated field value
          return { ...obj, [fieldName]: updatedArray };
        }
      
        // If the field doesn't exist or is not an array, return the original object
        return obj;
    }

    static insertValueAtEnd(inputArr, newValue) {
        if (inputArr === null || inputArr === undefined) {
            throw new Error("inputArr must not be null or undefined.");
        }
    
        if (!Array.isArray(inputArr)) {
            throw new Error("inputArr must be an array.");
        }
        return CollectionUtil.insertOrUpdateValueAt(inputArr, inputArr.length, newValue);
    }

    static insertOrUpdateValueAt(inputArr, index, newValue) {
        if (inputArr === null || inputArr === undefined) {
          throw new Error("inputArr must not be null or undefined.");
        }
      
        if (!Array.isArray(inputArr)) {
          throw new Error("inputArr must be an array.");
        }
      
        if (index >= 0 && index < inputArr.length) {
            // Update value at the existing index
            const updatedArray = [...inputArr];
            updatedArray[index] = newValue;
            return updatedArray;
        } else if (index === inputArr.length) {
            // Insert value at the end of the array
            return [...inputArr, newValue];
        } else {
            // Invalid index for updating or inserting
            throw new Error("Invalid index.");
        }
    }

    static deleteElementAt(inputArr, index) {
        if (!Array.isArray(inputArr)) {
          throw new Error("inputArr must be an array.");
        }
      
        if (index >= 0 && index < inputArr.length) {
          // Create a copy of the array without the element at the specified index
          const updatedArray = [...inputArr];
          CollectionUtil.removeFirstMatchFromArray(updatedArray , (el, elIndex) => index === elIndex);
          return updatedArray;
        } else {
          // Invalid index for deletion
          throw new Error("Invalid index.");
        }
    }
}
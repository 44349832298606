import StringUtil from "utils/StringUtil";

export class FileUtil {

    static getFileType(file) {
        if (file && StringUtil.isNotBlank(file.name)) {
            const index = file.name.lastIndexOf('.');
            if (index > 0) {
                const fileExt = file.name.substring(index + 1).toUpperCase();
                if (fileExt === 'PDF') {
                    return "PDF";
                } else if (fileExt === 'DOC' || fileExt === 'DOCX') {
                    return "DOC";
                } else if (fileExt === 'XLS' || fileExt === 'XLSX') {
                    return "EXCEL";
                } else if (fileExt === 'PPT' || fileExt === 'PPTX') {
                    return "PPT";
                } else if (fileExt === 'JPG' || fileExt === 'JPEG' || fileExt === 'PNG') {
                    return "IMAGE";
                } else if (fileExt === 'CSV') {
                    return "CSV";
                }
            }
        }
        return "ATTACHMENT";
    }

    static async readJsonFile(path) {
        try {
            // Fetch the JSON file from the public folder
            const response = await fetch(path);
            if (!response.ok) {
                throw new Error(`Failed to fetch file: ${path}`);
            }
            const data = await response.json();
            return data
        } catch (error) {
            console.error(`Error fetching navigation items: ${error.message}`);
            return [];
        }
    }
}

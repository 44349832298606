import { AddButton } from 'components/Buttons/ActionButtons';
import { SideDrawer } from 'components/Drawer/Drawer';
import { HorizontalTab, UiTabContext, UiTabPanel } from 'components/Tabs';
import Search from 'components/object-list/Search/Search';
import { PageableDataManagerProvider } from 'contexts/datamanager/PageableDataManagerContext';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StringUtil from 'utils/StringUtil';
import AddressCardGrid from 'views/account/address/AddressCardList';
import AddressTableList from 'views/account/address/AddressTableList';
import { AddressDetail } from './AddressDetail';
const PAGE_URL = '/account/address/page';

export default function AddressList() {
  const { t } = useTranslation();
  const [keywords, setKeywords] = useState(null);
  const [systemCode, setSystemCode] = useState();
  const [details, setShowDetails] = useState();
  const [showCardView] = useState(true);

  function showDetails(address) {
    if (address && StringUtil.isNotBlank(address.systemCode)) {
      setSystemCode(address.systemCode);
    } else {
      setSystemCode(null);
    }
    setShowDetails(true);
  }

  const allTabData = {
    keywords: keywords,
  };

  const tabs = [<HorizontalTab label='All' value='all' key='all' />];

  return (
    <>
      <SideDrawer anchor='right' headerTitle={t('addresses')} open={details} onClose={() => setShowDetails(false)}>
        <AddressDetail systemCode={systemCode} />
      </SideDrawer>
      <div className='applist applistshow'>
        <div className='applistheader'>
          <AddButton onClick={() => showDetails()} />
          <Search title={t('addresses')} setKeywords={setKeywords} />
        </div>
        <div className='applistframe'>
          <UiTabContext tabs={tabs} defaultTab={'all'}>
            <UiTabPanel value='all'>
              <PageableDataManagerProvider url={PAGE_URL} eventName={'DRIVE__CREATED'}>
                {showCardView ? <AddressCardGrid data={allTabData} showDetails={showDetails} /> : <AddressTableList data={allTabData} showDetails={showDetails} />}
              </PageableDataManagerProvider>
            </UiTabPanel>
          </UiTabContext>
        </div>
      </div>
    </>
  );
}


export default function LandingPage() {
  return (
      <div
        className="landing-page"
        style={{
        width : "100%",
        height : "100%",
        // backgroundImage: "url(https://images.unsplash.com/photo-1434725039720-aaad6dd32dfe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1642&q=80)"
      }}>
      </div>
  );
}

import { createContext, useEffect, useReducer } from 'react';
// CUSTOM COMPONENT
import HotlinkLoading from 'components/HotlinkLoading';
import useHttp from 'hooks/useHttp';
import { useLocation, useNavigate } from 'react-router-dom';

const initialState = {
  user: null,
  isInitialized: false,
  isAuthenticated: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialized: true, user };
    }

    case 'LOGIN': {
      return { ...state, isAuthenticated: true, user: action.payload.user };
    }

    case 'LOGOUT': {
      return { ...state, isAuthenticated: false, user: null, token: null };
    }

    case 'REGISTER': {
      return { ...state, isAuthenticated: false };
    }

    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  login: async () => {},
  logout: () => {},
  register: () => {},
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const router = useNavigate();
  const { get, post } = useHttp();
  const location = useLocation();

  const login = async (email, password) => {
    return post('/authenticate', { principal: email, password: password }).then(({data}) => {
      const { user } = data;
      dispatch({ type: 'LOGIN', payload: { user } });
      router('/');
    });
  };

  const register = async data => {
    post('/register', data).then((data) => {
      const { errors } = data;
      if (errors && errors.length > 0) {
      } else {
        dispatch({ type: 'REGISTER', payload: {} });
      }
    });
  };

  const logout = async () => {
    get('/signout').then(() => {
      dispatch({ type: 'LOGOUT' });
      router('/session/signin');
    });
  };

  useEffect(() => {
    get('/auth/profile', {}, true)
      .then(res => {
        dispatch({ type: 'INIT', payload: { isAuthenticated: true, user: res.user } });
      })
      .catch(() => {
        dispatch({ type: 'INIT', payload: { isAuthenticated: false, user: null } });
      });
  }, []);

  if (!state.isInitialized) return <HotlinkLoading />;

  return <AuthContext.Provider value={{ ...state, method: 'JWT', login, logout, register, token: state.token }}>{children}</AuthContext.Provider>;
};

export default AuthContext;

import { Box } from '@mui/material';

import { Body } from 'layout/frame/layout2/body';
import { Feed } from 'layout/frame/layout2/feed';
import Header from 'layout/frame/layout2/header';
import { Nav } from 'layout/frame/layout2/nav';

function Layout2({ children }) {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        overflow: 'hidden',
        backgroundImage:
          'url(https://images.unsplash.com/photo-1434725039720-aaad6dd32dfe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1642&q=80)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
      <Header />
      <Nav />
      <Body>{children}</Body>
      <Feed />
    </Box>
  );
}

export default Layout2;

import { Navigate } from 'react-router-dom';
import AuthGuard from 'auth/AuthGuard';
import LandingPage from 'views/home/landing-page';
import EmployeeList from 'views/account/employee/EmployeeList';
import PlatformUserList from 'views/system-admin/user/UserList';
import { AccountListingPage } from 'views/system-admin/account/AccountList';
import DriveList from 'views/drive/DriveList';
import UserDrive from 'views/drive/UserDrive';
import { Directory } from 'views/file-system/DirectoryList';
import { Bizmo } from 'views/bizmo/Bizmo';
import AccountCreationView from 'views/account/AccountCreationView';
import NotFound from 'views/sessions/NotFound';
import Login from 'views/sessions/Login';
import Register from 'views/sessions/Register';
import ForgotPassword from 'views/sessions/ForgotPassword';
import { VerifyJwtToken } from 'views/sessions/VerifyToken';
import RoleList from 'views/roles/RoleList';
import AddressList from 'views/account/address/AddressList';
import Layout2 from 'layout/frame/layout2/Layout2';
import SpeechToText from 'components/speech-to-text/SpeechToText';
import { OllamaExample } from 'components/ollama/Ollama';

const routes = [
  {
    element: (
      <AuthGuard>
        <Layout2 />
      </AuthGuard>
    ),
    children: [
      // ...materialRoutes,
      { path: '/hub', element: <LandingPage /> },
      { path: 'admin/teams', element: <EmployeeList /> },
      { path: '/account/apply', element: <AccountCreationView /> },
      { path: '/platform/admin/users', element: <PlatformUserList /> },
      { path: '/platform/admin/accounts/all', element: <AccountListingPage /> },

      // Drive Urls
      { path: '/drive/admin', element: <DriveList /> },
      { path: '/drive', element: <UserDrive /> },
      { path: '/folders/:driveCode/:driveName', element: <Directory /> },
      { path: '/bizmo', element: <Bizmo /> },

      { path: '/address', element: <AddressList /> },

      //Roles Urls
      { path: '/roles/admin', element: <RoleList /> },
    ],
  },

  // session pages route
  { path: '/session/404', element: <NotFound /> },
  { path: '/session/signin', element: <Login /> },
  { path: '/session/signup', element: <Register /> },
  { path: '/session/forgot-password', element: <ForgotPassword /> },
  { path: '/people/verify', element: <VerifyJwtToken /> },

  { path: '/', element: <Navigate to='hub' /> },
  { path: '*', element: <NotFound /> },
  { path: '/bizmo/fs', element: <Bizmo /> },
  { path: '/speech-to-text', element: <SpeechToText /> },
  { path: '/ollama', element: <OllamaExample /> },
];

export default routes;
